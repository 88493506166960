.app .fstr-cookie-notice {
  --cookie-notice-bg: var(--bs-secondary);
  --cookie-success-color: var(--bs-success);
  --cookie-muted-color: #{color(blue)};
  --cookie-disabled-color: #{color(blue-800)};

  border-radius: 20px !important;
  max-width: 600px;
  border-width: 0;
  border-radius: $border-radius-lg;
  padding: spacer(4);
  color: var(--bs-light);
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: auto;
  top: 50%;
  width: 90%;
  // background: linear-gradient(-180deg, #010420 0%, rgba(40, 7, 56, 0) 100%);
  background-color: #010420;
  box-shadow:
    0 20px 32px -22px rgba(0, 0, 0, 0.25),
    0 -20px 32px -22px rgba(0, 0, 0, 0.25);

  a {
    color: var(--bs-primary);
  }

  .button-group {
    margin-top: 10px;

    .button--reject {
      display: none;
    }
  }

  .fstr-cookie-notice__title {
    @extend .h6;
    margin-bottom: 1ex;
    color: var(--bs-primary);
    font-weight: 900;
  }

  .fstr-cookie-notice__toggle-row__content,
  .fstr-cookie-notice__content {
    // font-size: 0.85em;
    @include get-font-size('body-xs');
  }

  .fstr-cookie-notice__toggle-row__content.open {
    padding: 1ex 0;
  }

  .toggle-button {
    margin-left: 1ex;
    @include size(auto, auto);
    background-color: transparent;
    fill: theme-color(primary);
    color: theme-color(primary);
    padding: 0;

    span:first-of-type {
      display: none;
    }

    .chevron {
      margin-left: 0;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include media-breakpoint-down(md) {
      .button {
        width: 100%;
      }
    }
  }

  .button {
    @extend .button--sm;
    margin-bottom: 0 !important;
    text-align: center;
    justify-content: center;
  }

  .button--settings {
    border: 2px solid;
    color: var(--bs-primary);
    background-color: transparent;
  }

  // .button--primary {
  //   background-color: var(--bs-secondary);

  //   &:hover {
  //     background-color: var(--bs-secondary);
  //   }
  // }

  .button--close {
    @include abs($spacer, 0, null, null);
    @include size(40px, 40px);
    font-size: 0px;
    // border-color: var(--bs-secondary);
    background-color: transparent;
    padding: 0;
    // border: 2px solid;
    color: var(--bs-primary);

    &::after,
    &::before {
      @include abs(50%, null, null, 50%);
      content: '';
      width: 18px;
      height: 3px;
      border-radius: 2px;
      background-color: currentColor;
    }

    &::after {
      transform: translate3d(-50%, -50%) rotate3d(0, 0, 1, -45deg, 0);
    }

    &::before {
      transform: translate3d(-50%, -50%) rotate3d(0, 0, 1, 45deg, 0);
    }

    @include media-breakpoint-down(md) {
      max-width: 40px;
    }
  }

  .toggle input[type='checkbox']:disabled ~ label .toggle__switch::after {
    background-color: var(--bs-secondary);
    opacity: 0.5;
  }
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--bs-secondary-100);
  margin-bottom: 3ex;

  &__inner {
    @include abs(0, 0, 0, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .fstr-cookie-btn.button {
    font-size: 0.9em;
    margin-top: 3ex;
    background-color: var(--bs-primary);
  }
}
