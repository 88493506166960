@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button.button--#{$name} {
    --button-background: #{$bg-color};
    --button-background-darker: #{darkenColor($bg-color, 0.2)};
    --button-background-alpha: #{alpha($bg-color, 0.2)};
    --button-color: #{$font-color};
  }
}
