.debug {
  @include fxd(0, 0, auto, auto);
  background: black;
  color: white;
  width: clamp(100px, 300px, 100vw);
  border: 1px solid white;
  font-family: monospace;
  padding: 5px;
  max-height: 300px;
  overflow: auto;
}
