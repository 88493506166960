.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--bs-primary-bg-subtle);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--bs-primary-600); // placeholder
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--bs-primary-800); // value
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-color: var(--bs-primary);
}

.mat-mdc-form-field {
  width: 100%;
}
