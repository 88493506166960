.pulse {
  @include size(50px);
  position: relative;
  pointer-events: none;
  z-index: 4;
  animation: scaleIn 0.3s ease-out both;

  &__ring {
    position: absolute;
    background-color: var(--bs-light);
    height: 100%;
    width: 100%;
    border-radius: 100%;
    opacity: 0.4;
    left: 0;
    top: 0;
    animation: pulsing 2.4s ease-out infinite;

    &:nth-of-type(1) {
      animation-delay: -0.5s;
    }
    &:nth-of-type(2) {
      animation-delay: -1s;
    }
    &:nth-of-type(3) {
      animation-delay: -1.5s;
    }
  }

  @include media-breakpoint-up(sm) {
    @include size(80px);
  }
}

@keyframes pulsing {
  100% {
    transform: scale(1.75);
    opacity: 0;
  }
}

@keyframes scaleIn {
  0% {
    transform: translate3d(-50%, -50%) scale(0.5, 0);
  }
  100% {
    transform: translate3d(-50%, -50%) scale(1, 0);
  }
}
