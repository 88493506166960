.theme-geel,
[data-bs-theme='geel'] {
  --bs-secondary: #{color(yellow)};
  --bs-secondary-bg-subtle: #{color(blue-900)};
  --bs-secondary-subtle: #{color(blue-900)};

  $color: color(yellow);

  @include defineColorHSL(--bs-secondary, hue($color), saturation($color), lightness($color));
}
