@import 'all';

.logo-intro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100vh;

  &__wrapper {
    animation: fade-in-intro 0.4s 0.2s ease-in-out both;
  }

  &__title {
    position: relative;
    z-index: 10;
    font-weight: 700;

    svg {
      @include size(60vw, 50vh);
      max-width: 670px;
      max-height: 230px;
    }
  }

  .char {
    will-change: transform;
    // display: inline-block;
    fill: #fff;
    position: relative;
    animation: blur-and-zoom 3s infinite;
    transform-origin: center center;

    // svg {
    //   @include size(60px, 80px);
    //   fill: #fff;

    //   @include media-breakpoint-up(md) {
    //     @include size(160px, 220px);
    //   }
    // }

    &:nth-child(2),
    &:nth-child(1),
    &:nth-child(5) {
      filter: url(#svgBlur);
    }

    &:nth-child(3),
    &:nth-child(4) {
      filter: url(#svgBlur-2);
    }

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 1s;
    }

    &:nth-child(3) {
      animation-delay: 1.3s;
    }

    &:nth-child(4) {
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      animation-delay: 1.4s;
    }
  }

  .intro-circle-bg {
    opacity: 0.8;
    will-change: opacity, transform;

    &.bg--blue {
      animation: bg-animation-blue 12s infinite both;
    }

    &.bg--magenta {
      animation: bg-animation-magenta 10s 0.3s infinite both;
    }
  }
}

@keyframes blur-and-zoom {
  0%,
  100% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

@keyframes fade-in-intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bg-animation-blue {
  0%,
  100% {
    opacity: 0.5;
    // transform: translate3d(-70%, -40%, 0);
    transform: translate3d(-65%, -80%, 0);
  }
  50% {
    opacity: 0.8;
    transform: translate3d(-70%, -40%, 0);
    //transform: translate3d(-65%, -80%, 0);
  }
}

@keyframes bg-animation-magenta {
  0%,
  100% {
    opacity: 0.5;
    //transform: translate3d(-20%, -75%, 0);
    transform: translate3d(-40%, -25%, 0);
  }
  50% {
    opacity: 0.8;
    // transform: translate3d(-40%, -25%, 0);
    transform: translate3d(-20%, -75%, 0);
  }
}
