.badge {
  font-size: map-get($font-sizes, 'body-xs');
  font-weight: 900;
  padding: 0 1.5ex;
  line-height: rem(23px);
  border-radius: $border-radius-sm;
  font-family: $headings-font-family;
  display: inline-flex;
  align-items: center;
  background-color: var(--badge-default-bg);
  color: var(--badge-default-color);
}

@each $name, $colors in $badges-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .badge--#{$name} {
    --badge-default-bg: #{$bg-color};
    --badge-default-color: #{$font-color};
  }
}
