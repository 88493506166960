.hamburger {
  $timing: 0.2s;
  $easing: cubic-bezier(0.23, 1, 0.32, 1);

  // --hamburger-width: 15px;
  // --hamburger-line-height: 2px;
  // --hamburger-spacing: 2px;

  height: calc((var(--hamburger-spacing) * 2) + (var(--hamburger-line-height) * 3));
  width: var(--hamburger-width);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--bs-primary);
  padding-top: 2px;

  //.main-nav--active & {
  //  color: var(--bs-primary);
  //}

  &__lines {
    position: relative;
    background: transparent;

    &::before,
    &::after {
      width: var(--hamburger-width);
      height: var(--hamburger-line-height);
      display: inline-block;
      content: '';
      background: currentColor;
      transform-origin: calc(50% - 1px) 50%;
    }

    &::before {
      @include abs(auto, 0, var(--hamburger-spacing), 0);

      transition:
        background $timing,
        bottom $timing $timing $easing,
        transform $timing $easing;
    }

    &::after {
      @include abs(var(--hamburger-spacing), 0, auto, 0);

      transition:
        background $timing,
        top $timing $timing $easing,
        transform $timing $easing;
    }
  }

  &.is--active {
    .hamburger__lines {
      //color: var(--bs-primary);
      // stylelint-disable declaration-no-important
      background: transparent !important;

      &::before {
        bottom: 0;
        transition:
          background $timing,
          bottom $timing $easing,
          transform $timing $timing $easing;
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        top: 0;
        transition:
          background $timing,
          top $timing $easing,
          transform $timing $timing $easing;
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
.hamburger {
  transition: transform 0.2s ease-in-out;

  &-button {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__icon {
      transition: transform 0.2s linear;
      transform: rotate3d(0, 0, 1, 0deg);
    }

    &:hover .hamburger-button__icon {
      transform: scale(0.8);
    }
  }
  &.is--active {
    .hamburger-button__icon {
      transform: rotate3d(0, 0, 1, -135deg);
    }
  }
}
