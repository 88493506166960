.anim-fade-up {
  opacity: 0;
  transform: translate3d(0, 20%, 0);
  transition:
    transform 0.5s var(--delay, 0s),
    opacity 0.5s var(--delay, 0s);

  &.anim--active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
