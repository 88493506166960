.markdown-content {
  line-height: $paragraph-line-height;

  $markdown-font-sizes: (
    h1: 42px,
    h2: 34px,
    h3: 28px,
    h4: 22px,
    h5: 18px,
    h6: 18px,
  );

  @import './blockquote';
  @import './image';
  @import './table';
  @import './type';
  @import './media';
}

@import './simple-markdown';
@import './embed-removed';
