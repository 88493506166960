// Reset
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

.mat-expansion-panel-header:not([aria-disabled='true']) {
  .mat-expansion-panel.is--disabled & {
    cursor: default;
  }
}

// Styles
.mat-expansion-panel {
  --padding-offset: 20px;

  margin-left: calc(var(--padding-offset) * -1);
  border-radius: 10px !important;
  overflow: hidden;
  background: transparent;
  transition: background 0.3s ease-in-out;

  .mat-expansion-panel-header {
    padding: 15px var(--padding-offset);
  }

  //custom div wrapped around content
  // TODO remove if not open? animate? idk
  &__content {
    padding: 0 var(--padding-offset) var(--padding-offset) var(--padding-offset);
  }

  &.is--disabled {
    cursor: default;
  }

  @include media-breakpoint-up(md) {
    &.has-no--text {
      //pointer-events: none;
      .mat-expansion-indicator {
        display: none;
      }

      .mat-expansion-panel__content {
        display: none;
      }
    }
  }

  .mat-expansion-panel-content {
    pointer-events: auto;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  &.is--only-panel-with-content {
    pointer-events: none;
  }

  //:not(.has-no--text)
  &:not(.is--disabled).mat-expanded {
    background: linear-gradient(57deg, #010420 19.82%, rgba(40, 7, 56, 0) 84.66%);
  }

  &:not(.is--disabled):not(.mat-expanded):hover {
    background: linear-gradient(57deg, rgba(#010420, 0.5) 19.82%, rgba(40, 7, 56, 0) 84.66%);
  }

  &:not(.is--disabled):not(.mat-expanded):hover .mat-expansion-panel-header {
    color: var(--bs-primary);
  }
}

// minimize extra spacing animation
//.mat-accordion > .mat-expansion-panel-spacing {
//  margin: 5px 0;

//  &.has-no--text {
//    margin: 0;
//  }
//}

mat-accordion {
  .mat-expansion-panel {
    background: none;
    color: white;
  }
  .mat-expansion-panel-header-title {
    color: white;
    align-items: flex-start !important;
  }

  .mat-expansion-panel-header {
    height: auto !important;
  }

  .mat-expansion-indicator::after {
    transition: transform 0.2s;
  }
  .mat-expanded .mat-expansion-indicator::after {
    translate: 0 -50%;
  }

  .mat-expansion-indicator::after {
    color: white;
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: transparent;
  }
}
