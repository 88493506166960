.simple-markdown {
  line-height: $paragraph-line-height;

  $markdown-font-sizes: (
    h1: 42px,
    h2: 34px,
    h3: 28px,
    h4: 22px,
    h5: 18px,
    h6: 18px,
  );

  @import './type';

  p:last-child {
    margin-bottom: 0;
  }

  strong {
    color: var(--bs-secondary);
  }

  // TODO fix this in the SDK so the html wont be rendered in the first place
  .fr-video,
  img,
  .fr-img-space-wrap,
  .fr-inner,
  .embed-removed {
    display: none;
  }
}
