.block-default {
  --block-spacing: 40px;
  padding: var(--block-spacing);
  position: relative;
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-up(xl) {
    --block-spacing: 60px;
  }

  &::before {
    content: '';
    padding-bottom: 100%;
    display: block;
  }

  &__figure {
    @include abs(var(--block-spacing));
    @include size(calc(100% - var(--block-spacing) * 2));
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;

    &__label {
      position: absolute;
      left: calc(var(--block-spacing) * -0.5);

      transform: rotate3d(0, 0, 1, -90deg);
      transform-origin: left center;
      animation: width 2s infinite;
      bottom: -0.9em;

      @include media-breakpoint-up(xl) {
        font-size: 1.5em;
      }
    }
  }
}
