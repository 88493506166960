.background-circles {
  aspect-ratio: 1/1;

  circle {
    stroke-width: var(--stroke-width, 1);
    stroke: var(--stroke-color, #fff);
    stroke-dasharray: var(--stroke-dasharray, 13px);
  }
}

.intro-circle-bg {
  @include fxd(50%, null, null, 50%);
  pointer-events: none;
  transform: translate3d(-55%, -45%, 0);
  z-index: 2;
  opacity: 0.5;
  width: 50vw;
  height: 50vw;

  &.bg--blue {
    // background: radial-gradient(circle, rgba(53, 119, 171, 0.67) 20%, rgba(53, 119, 171, 0) 60%);
    background: radial-gradient(circle, alpha(var(--bs-blue), 0.67) 20%, alpha(var(--bs-blue), 0) 60%);
  }

  &.bg--magenta {
    transform: translate3d(-20%, -75%, 0);
    // background: radial-gradient(circle, rgba(230, 0, 127, 0.63) 20%, rgba(230, 0, 127, 0) 60%);
    background: radial-gradient(circle, alpha(var(--bs-pink), 0.67) 20%, alpha(var(--bs-pink), 0) 60%);
  }
}
