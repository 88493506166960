.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.font-display {
  font-family: $display-font-family;
}

.link-unset,
.block-link {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.fw-semi {
  font-weight: 300 !important;
}
.fw-bolder {
  font-weight: 900 !important;
}

@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: var(--bs-#{$color}) !important;
  }
}

.font-handwritten {
  font-family: $font-family-handwritten !important;
}
