.anim--spinning {
  animation: spin 2.2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
