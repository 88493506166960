@import 'all';

.content-page {
  max-width: 100%;
  overflow: hidden;
}

.section {
  position: relative;

  &__background-blur {
    @include abs();

    max-width: 1200px;
    transform: translate(-10%, -15%);
    pointer-events: none;
  }
}
