// Imports
@import url('https://use.typekit.net/sns2jyp.css');

// Bootstrap
$font-size-base: 18;

$font-family-sans-serif: 'museo-sans', sans-serif;
$font-family-serif: 'museo', serif;
$font-family-handwritten: 'felt-tip-woman', sans-serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 64px,
  2: 54px,
  3: 48px,
  4: 36px,
  5: 32px,
  6: 24px,
  body-xs: 15px,
  body-sm: 16px,
  body-smd: 18px,
  body-md: 20px,
  body-lg: 24px,
);

// golden ratio uitgaande vanuit grootste font formaat)
$display-font-sizes: (
  1: 246px,
  2: 178px,
  3: 109px,
  //golden ratio
  4: 68px,
  // golden ratio
  5: 54px,
  //golden ratio
  6: 26px //golden ratio,,,,,,,,,,,,,,,,,
);

$headings-font-family: $font-family-serif;
$headings-font-weight: 700;
$headings-line-height: 1.1;

$display-font-family: $font-family-serif;
$display-font-style: null;
$display-font-weight: 900;
$display-line-height: $headings-line-height;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;

$lead-font-size: map-get($font-sizes, body-lg);
$lead-font-weight: 500;

// Other
//? Put own extra variables here

$paragraph-line-height: 1.6;
