.reveal-effect {
  .words {
    background: var(--text-underlying-color, #ccc) linear-gradient(to right, var(--text-color, #000) 0%, var(--text-color, #000) 100%) 0 / 0
      no-repeat;
    background-clip: text;
    color: transparent;
    background-size: calc(var(--scroll-text-currrent-char) * 1ch);
  }

  .is--highlighted .word::after {
    content: attr(data-word);
    position: absolute;
    background: var(--text-underlying-color, var(--bs-primary)) linear-gradient(to right, var(--bs-primary) 0%, var(--bs-primary) 100%) 0/0
      no-repeat;
    z-index: 2;
    transform: translate3d(-100%, 0px, 0);
    background-clip: text;
    color: transparent;
    background-size: calc((var(--scroll-text-currrent-char) - var(--char-index)) * 1ch);
  }
}
