@charset "UTF-8";
@import url("https://use.typekit.net/sns2jyp.css");
@import url("https://use.typekit.net/sns2jyp.css");
@import url("https://use.typekit.net/sns2jyp.css");
:root {
  --badge-default-bg: var(--bs-primary);
  --badge-default-color: var(--bs-primary-bg-subtle);
}

:root,
[data-bs-theme=light] {
  --bs-blue: #0878af;
  --bs-blue-800: #061235;
  --bs-blue-900: #01072a;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e6007f;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fff500;
  --bs-green: #80ca6e;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-gray-900: #212529;
  --bs-gray-800: #343a40;
  --bs-gray-700: #495057;
  --bs-gray-600: #6c757d;
  --bs-gray-500: #adb5bd;
  --bs-gray-400: #ced4da;
  --bs-gray-300: #dee2e6;
  --bs-gray-200: #e9ecef;
  --bs-gray-100: #f8f9fa;
  --bs-primary: #fff500;
  --bs-secondary: #0878af;
  --bs-success: #80ca6e;
  --bs-info: #0878af;
  --bs-warning: #fd7e14;
  --bs-danger: #dc3545;
  --bs-light: white;
  --bs-dark: #010421;
  --bs-muted: #adb5bd;
  --bs-primary-rgb: 255, 245, 0;
  --bs-secondary-rgb: 8, 120, 175;
  --bs-success-rgb: 128, 202, 110;
  --bs-info-rgb: 8, 120, 175;
  --bs-warning-rgb: 253, 126, 20;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 1, 4, 33;
  --bs-muted-rgb: 173, 181, 189;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #01072a;
  --bs-secondary-bg-subtle: #01072a;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "museo-sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "museo-sans", sans-serif;
  --bs-body-font-size: 18;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.5;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #01072a;
  --bs-body-bg-rgb: 1, 7, 42;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #fff;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 10px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 20px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(1, 4, 33, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(1, 4, 33, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(1, 4, 33, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(1, 4, 33, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xsm, .container {
    max-width: 800px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-xsm: 390px;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 6rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 6rem;
}

@media (min-width: 390px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
  .gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
  .gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
  .gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
  .gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
  .gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
  .gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
  .gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
  .gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
  .gx-xsm-4 {
    --bs-gutter-x: 2rem;
  }
  .g-xsm-4,
  .gy-xsm-4 {
    --bs-gutter-y: 2rem;
  }
  .g-xsm-5,
  .gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
  .gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xsm-6,
  .gx-xsm-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xsm-6,
  .gy-xsm-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xsm-7,
  .gx-xsm-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xsm-7,
  .gy-xsm-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xsm-8,
  .gx-xsm-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xsm-8,
  .gy-xsm-8 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 6rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 6rem;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-muted {
  --bs-focus-ring-color: rgba(var(--bs-muted-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-7 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-8 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 2rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4rem !important;
}

.me-7 {
  margin-right: 5rem !important;
}

.me-8 {
  margin-right: 6rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 2rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4rem !important;
}

.ms-7 {
  margin-left: 5rem !important;
}

.ms-8 {
  margin-left: 6rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-7 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-8 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pt-8 {
  padding-top: 6rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 2rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4rem !important;
}

.pe-7 {
  padding-right: 5rem !important;
}

.pe-8 {
  padding-right: 6rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.pb-8 {
  padding-bottom: 6rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 2rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4rem !important;
}

.ps-7 {
  padding-left: 5rem !important;
}

.ps-8 {
  padding-left: 6rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 2rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4rem !important;
}

.gap-7 {
  gap: 5rem !important;
}

.gap-8 {
  gap: 6rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 2rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 4rem !important;
}

.row-gap-7 {
  row-gap: 5rem !important;
}

.row-gap-8 {
  row-gap: 6rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 2rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 4rem !important;
}

.column-gap-7 {
  column-gap: 5rem !important;
}

.column-gap-8 {
  column-gap: 6rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-2 {
  font-size: calc(1.4625rem + 2.55vw) !important;
}

.fs-3 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-4 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-5 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-6 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-body-xs {
  font-size: 0.9375rem !important;
}

.fs-body-sm {
  font-size: 1rem !important;
}

.fs-body-smd {
  font-size: 1.125rem !important;
}

.fs-body-md {
  font-size: 1.25rem !important;
}

.fs-body-lg {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-muted {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-muted-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 390px) {
  .float-xsm-start {
    float: left !important;
  }
  .float-xsm-end {
    float: right !important;
  }
  .float-xsm-none {
    float: none !important;
  }
  .object-fit-xsm-contain {
    object-fit: contain !important;
  }
  .object-fit-xsm-cover {
    object-fit: cover !important;
  }
  .object-fit-xsm-fill {
    object-fit: fill !important;
  }
  .object-fit-xsm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xsm-none {
    object-fit: none !important;
  }
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-inline-grid {
    display: inline-grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 1rem !important;
  }
  .m-xsm-4 {
    margin: 2rem !important;
  }
  .m-xsm-5 {
    margin: 3rem !important;
  }
  .m-xsm-6 {
    margin: 4rem !important;
  }
  .m-xsm-7 {
    margin: 5rem !important;
  }
  .m-xsm-8 {
    margin: 6rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xsm-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xsm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xsm-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xsm-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xsm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xsm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xsm-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xsm-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xsm-0 {
    margin-top: 0 !important;
  }
  .mt-xsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xsm-4 {
    margin-top: 2rem !important;
  }
  .mt-xsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xsm-6 {
    margin-top: 4rem !important;
  }
  .mt-xsm-7 {
    margin-top: 5rem !important;
  }
  .mt-xsm-8 {
    margin-top: 6rem !important;
  }
  .mt-xsm-auto {
    margin-top: auto !important;
  }
  .me-xsm-0 {
    margin-right: 0 !important;
  }
  .me-xsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-right: 1rem !important;
  }
  .me-xsm-4 {
    margin-right: 2rem !important;
  }
  .me-xsm-5 {
    margin-right: 3rem !important;
  }
  .me-xsm-6 {
    margin-right: 4rem !important;
  }
  .me-xsm-7 {
    margin-right: 5rem !important;
  }
  .me-xsm-8 {
    margin-right: 6rem !important;
  }
  .me-xsm-auto {
    margin-right: auto !important;
  }
  .mb-xsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xsm-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xsm-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xsm-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xsm-8 {
    margin-bottom: 6rem !important;
  }
  .mb-xsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xsm-0 {
    margin-left: 0 !important;
  }
  .ms-xsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xsm-4 {
    margin-left: 2rem !important;
  }
  .ms-xsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xsm-6 {
    margin-left: 4rem !important;
  }
  .ms-xsm-7 {
    margin-left: 5rem !important;
  }
  .ms-xsm-8 {
    margin-left: 6rem !important;
  }
  .ms-xsm-auto {
    margin-left: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 1rem !important;
  }
  .p-xsm-4 {
    padding: 2rem !important;
  }
  .p-xsm-5 {
    padding: 3rem !important;
  }
  .p-xsm-6 {
    padding: 4rem !important;
  }
  .p-xsm-7 {
    padding: 5rem !important;
  }
  .p-xsm-8 {
    padding: 6rem !important;
  }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xsm-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xsm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xsm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xsm-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xsm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xsm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xsm-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xsm-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .pt-xsm-0 {
    padding-top: 0 !important;
  }
  .pt-xsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xsm-4 {
    padding-top: 2rem !important;
  }
  .pt-xsm-5 {
    padding-top: 3rem !important;
  }
  .pt-xsm-6 {
    padding-top: 4rem !important;
  }
  .pt-xsm-7 {
    padding-top: 5rem !important;
  }
  .pt-xsm-8 {
    padding-top: 6rem !important;
  }
  .pe-xsm-0 {
    padding-right: 0 !important;
  }
  .pe-xsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xsm-4 {
    padding-right: 2rem !important;
  }
  .pe-xsm-5 {
    padding-right: 3rem !important;
  }
  .pe-xsm-6 {
    padding-right: 4rem !important;
  }
  .pe-xsm-7 {
    padding-right: 5rem !important;
  }
  .pe-xsm-8 {
    padding-right: 6rem !important;
  }
  .pb-xsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xsm-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xsm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xsm-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xsm-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xsm-8 {
    padding-bottom: 6rem !important;
  }
  .ps-xsm-0 {
    padding-left: 0 !important;
  }
  .ps-xsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xsm-4 {
    padding-left: 2rem !important;
  }
  .ps-xsm-5 {
    padding-left: 3rem !important;
  }
  .ps-xsm-6 {
    padding-left: 4rem !important;
  }
  .ps-xsm-7 {
    padding-left: 5rem !important;
  }
  .ps-xsm-8 {
    padding-left: 6rem !important;
  }
  .gap-xsm-0 {
    gap: 0 !important;
  }
  .gap-xsm-1 {
    gap: 0.25rem !important;
  }
  .gap-xsm-2 {
    gap: 0.5rem !important;
  }
  .gap-xsm-3 {
    gap: 1rem !important;
  }
  .gap-xsm-4 {
    gap: 2rem !important;
  }
  .gap-xsm-5 {
    gap: 3rem !important;
  }
  .gap-xsm-6 {
    gap: 4rem !important;
  }
  .gap-xsm-7 {
    gap: 5rem !important;
  }
  .gap-xsm-8 {
    gap: 6rem !important;
  }
  .row-gap-xsm-0 {
    row-gap: 0 !important;
  }
  .row-gap-xsm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xsm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xsm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xsm-4 {
    row-gap: 2rem !important;
  }
  .row-gap-xsm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xsm-6 {
    row-gap: 4rem !important;
  }
  .row-gap-xsm-7 {
    row-gap: 5rem !important;
  }
  .row-gap-xsm-8 {
    row-gap: 6rem !important;
  }
  .column-gap-xsm-0 {
    column-gap: 0 !important;
  }
  .column-gap-xsm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xsm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xsm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xsm-4 {
    column-gap: 2rem !important;
  }
  .column-gap-xsm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xsm-6 {
    column-gap: 4rem !important;
  }
  .column-gap-xsm-7 {
    column-gap: 5rem !important;
  }
  .column-gap-xsm-8 {
    column-gap: 6rem !important;
  }
  .text-xsm-start {
    text-align: left !important;
  }
  .text-xsm-end {
    text-align: right !important;
  }
  .text-xsm-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 2rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 2rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4rem !important;
  }
  .mt-sm-7 {
    margin-top: 5rem !important;
  }
  .mt-sm-8 {
    margin-top: 6rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 2rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4rem !important;
  }
  .me-sm-7 {
    margin-right: 5rem !important;
  }
  .me-sm-8 {
    margin-right: 6rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 2rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4rem !important;
  }
  .ms-sm-7 {
    margin-left: 5rem !important;
  }
  .ms-sm-8 {
    margin-left: 6rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 2rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 2rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4rem !important;
  }
  .pt-sm-7 {
    padding-top: 5rem !important;
  }
  .pt-sm-8 {
    padding-top: 6rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 2rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4rem !important;
  }
  .pe-sm-7 {
    padding-right: 5rem !important;
  }
  .pe-sm-8 {
    padding-right: 6rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 6rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 2rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4rem !important;
  }
  .ps-sm-7 {
    padding-left: 5rem !important;
  }
  .ps-sm-8 {
    padding-left: 6rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 2rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4rem !important;
  }
  .gap-sm-7 {
    gap: 5rem !important;
  }
  .gap-sm-8 {
    gap: 6rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 6rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 6rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 2rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 2rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4rem !important;
  }
  .mt-md-7 {
    margin-top: 5rem !important;
  }
  .mt-md-8 {
    margin-top: 6rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 2rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4rem !important;
  }
  .me-md-7 {
    margin-right: 5rem !important;
  }
  .me-md-8 {
    margin-right: 6rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4rem !important;
  }
  .mb-md-7 {
    margin-bottom: 5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 6rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 2rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4rem !important;
  }
  .ms-md-7 {
    margin-left: 5rem !important;
  }
  .ms-md-8 {
    margin-left: 6rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 2rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 2rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4rem !important;
  }
  .pt-md-7 {
    padding-top: 5rem !important;
  }
  .pt-md-8 {
    padding-top: 6rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 2rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4rem !important;
  }
  .pe-md-7 {
    padding-right: 5rem !important;
  }
  .pe-md-8 {
    padding-right: 6rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4rem !important;
  }
  .pb-md-7 {
    padding-bottom: 5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 6rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 2rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4rem !important;
  }
  .ps-md-7 {
    padding-left: 5rem !important;
  }
  .ps-md-8 {
    padding-left: 6rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 2rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4rem !important;
  }
  .gap-md-7 {
    gap: 5rem !important;
  }
  .gap-md-8 {
    gap: 6rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 2rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 4rem !important;
  }
  .row-gap-md-7 {
    row-gap: 5rem !important;
  }
  .row-gap-md-8 {
    row-gap: 6rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 2rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 4rem !important;
  }
  .column-gap-md-7 {
    column-gap: 5rem !important;
  }
  .column-gap-md-8 {
    column-gap: 6rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 2rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 2rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4rem !important;
  }
  .mt-lg-7 {
    margin-top: 5rem !important;
  }
  .mt-lg-8 {
    margin-top: 6rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 2rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4rem !important;
  }
  .me-lg-7 {
    margin-right: 5rem !important;
  }
  .me-lg-8 {
    margin-right: 6rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 2rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4rem !important;
  }
  .ms-lg-7 {
    margin-left: 5rem !important;
  }
  .ms-lg-8 {
    margin-left: 6rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 2rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 2rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4rem !important;
  }
  .pt-lg-7 {
    padding-top: 5rem !important;
  }
  .pt-lg-8 {
    padding-top: 6rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 2rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4rem !important;
  }
  .pe-lg-7 {
    padding-right: 5rem !important;
  }
  .pe-lg-8 {
    padding-right: 6rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 6rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 2rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4rem !important;
  }
  .ps-lg-7 {
    padding-left: 5rem !important;
  }
  .ps-lg-8 {
    padding-left: 6rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 2rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4rem !important;
  }
  .gap-lg-7 {
    gap: 5rem !important;
  }
  .gap-lg-8 {
    gap: 6rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 6rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 6rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 2rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 2rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4rem !important;
  }
  .mt-xl-7 {
    margin-top: 5rem !important;
  }
  .mt-xl-8 {
    margin-top: 6rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 2rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4rem !important;
  }
  .me-xl-7 {
    margin-right: 5rem !important;
  }
  .me-xl-8 {
    margin-right: 6rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 2rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4rem !important;
  }
  .ms-xl-7 {
    margin-left: 5rem !important;
  }
  .ms-xl-8 {
    margin-left: 6rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 2rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 2rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4rem !important;
  }
  .pt-xl-7 {
    padding-top: 5rem !important;
  }
  .pt-xl-8 {
    padding-top: 6rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 2rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4rem !important;
  }
  .pe-xl-7 {
    padding-right: 5rem !important;
  }
  .pe-xl-8 {
    padding-right: 6rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 6rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 2rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4rem !important;
  }
  .ps-xl-7 {
    padding-left: 5rem !important;
  }
  .ps-xl-8 {
    padding-left: 6rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 2rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4rem !important;
  }
  .gap-xl-7 {
    gap: 5rem !important;
  }
  .gap-xl-8 {
    gap: 6rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 6rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 6rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 2rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4rem !important;
  }
  .m-xxl-7 {
    margin: 5rem !important;
  }
  .m-xxl-8 {
    margin: 6rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4rem !important;
  }
  .mt-xxl-7 {
    margin-top: 5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 6rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 2rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4rem !important;
  }
  .me-xxl-7 {
    margin-right: 5rem !important;
  }
  .me-xxl-8 {
    margin-right: 6rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4rem !important;
  }
  .ms-xxl-7 {
    margin-left: 5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 6rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 2rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4rem !important;
  }
  .p-xxl-7 {
    padding: 5rem !important;
  }
  .p-xxl-8 {
    padding: 6rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4rem !important;
  }
  .pt-xxl-7 {
    padding-top: 5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 6rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4rem !important;
  }
  .pe-xxl-7 {
    padding-right: 5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 6rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 6rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4rem !important;
  }
  .ps-xxl-7 {
    padding-left: 5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 6rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 2rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4rem !important;
  }
  .gap-xxl-7 {
    gap: 5rem !important;
  }
  .gap-xxl-8 {
    gap: 6rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 6rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 6rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 4rem !important;
  }
  .fs-2 {
    font-size: 3.375rem !important;
  }
  .fs-3 {
    font-size: 3rem !important;
  }
  .fs-4 {
    font-size: 2.25rem !important;
  }
  .fs-5 {
    font-size: 2rem !important;
  }
  .fs-6 {
    font-size: 1.5rem !important;
  }
  .fs-body-lg {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #fff500;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #fff500;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #dc3545;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #fff500;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fff500;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #fff500;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fff500;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #fff500;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fff500;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc3545;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc3545;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: museo-sans, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: museo-sans, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: museo-sans, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: museo-sans, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #fff500;
  --mdc-linear-progress-track-color: rgba(255, 245, 0, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 245, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 245, 0, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 245, 0, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 245, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 245, 0, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #fff500;
  --mdc-linear-progress-track-color: rgba(255, 245, 0, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 245, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 245, 0, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 245, 0, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 245, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 245, 0, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc3545;
  --mdc-linear-progress-track-color: rgba(220, 53, 69, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(220, 53, 69, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(220, 53, 69, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 53, 69, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 53, 69, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(220, 53, 69, 0.25));
}
html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: museo-sans, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #fff500;
  --mdc-filled-text-field-focus-active-indicator-color: #fff500;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 245, 0, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #dc3545;
  --mdc-filled-text-field-error-label-text-color: #dc3545;
  --mdc-filled-text-field-error-caret-color: #dc3545;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc3545;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc3545;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc3545;
  --mdc-outlined-text-field-caret-color: #fff500;
  --mdc-outlined-text-field-focus-outline-color: #fff500;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 245, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc3545;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc3545;
  --mdc-outlined-text-field-error-label-text-color: #dc3545;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc3545;
  --mdc-outlined-text-field-error-hover-outline-color: #dc3545;
  --mdc-outlined-text-field-error-outline-color: #dc3545;
  --mat-form-field-focus-select-arrow-color: rgba(255, 245, 0, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc3545;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.12;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #fff500;
  --mdc-filled-text-field-focus-active-indicator-color: #fff500;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 245, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #fff500;
  --mdc-outlined-text-field-focus-outline-color: #fff500;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 245, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 245, 0, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc3545;
  --mdc-filled-text-field-focus-active-indicator-color: #dc3545;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 53, 69, 0.87);
  --mdc-outlined-text-field-caret-color: #dc3545;
  --mdc-outlined-text-field-focus-outline-color: #dc3545;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 53, 69, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 53, 69, 0.87);
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mdc-filled-text-field-label-text-font: museo-sans, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: museo-sans, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: museo-sans, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: museo-sans, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 245, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 53, 69, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 245, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 53, 69, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 53, 69, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 53, 69, 0.87);
}

html {
  --mat-select-trigger-text-font: museo-sans, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: museo-sans, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: museo-sans, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #fff500;
  --mdc-chip-elevated-disabled-container-color: #fff500;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #fff500;
  --mdc-chip-elevated-disabled-container-color: #fff500;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc3545;
  --mdc-chip-elevated-disabled-container-color: #dc3545;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: museo-sans, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

.mdc-switch {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-state-layer-size: 40px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #fff400;
  --mdc-switch-selected-handle-color: #fff400;
  --mdc-switch-selected-hover-state-layer-color: #fff400;
  --mdc-switch-selected-pressed-state-layer-color: #fff400;
  --mdc-switch-selected-focus-handle-color: #ffee00;
  --mdc-switch-selected-hover-handle-color: #ffee00;
  --mdc-switch-selected-pressed-handle-color: #ffee00;
  --mdc-switch-selected-focus-track-color: #fff84d;
  --mdc-switch-selected-hover-track-color: #fff84d;
  --mdc-switch-selected-pressed-track-color: #fff84d;
  --mdc-switch-selected-track-color: #fff84d;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fff400;
  --mdc-switch-selected-handle-color: #fff400;
  --mdc-switch-selected-hover-state-layer-color: #fff400;
  --mdc-switch-selected-pressed-state-layer-color: #fff400;
  --mdc-switch-selected-focus-handle-color: #ffee00;
  --mdc-switch-selected-hover-handle-color: #ffee00;
  --mdc-switch-selected-pressed-handle-color: #ffee00;
  --mdc-switch-selected-focus-track-color: #fff84d;
  --mdc-switch-selected-hover-track-color: #fff84d;
  --mdc-switch-selected-pressed-track-color: #fff84d;
  --mdc-switch-selected-track-color: #fff84d;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #d8303f;
  --mdc-switch-selected-handle-color: #d8303f;
  --mdc-switch-selected-hover-state-layer-color: #d8303f;
  --mdc-switch-selected-pressed-state-layer-color: #d8303f;
  --mdc-switch-selected-focus-handle-color: #c61620;
  --mdc-switch-selected-hover-handle-color: #c61620;
  --mdc-switch-selected-pressed-handle-color: #c61620;
  --mdc-switch-selected-focus-track-color: #e7727d;
  --mdc-switch-selected-hover-track-color: #e7727d;
  --mdc-switch-selected-pressed-track-color: #e7727d;
  --mdc-switch-selected-track-color: #e7727d;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: museo-sans, sans-serif;
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: 0.0178571429em;
  --mat-slide-toggle-label-text-line-height: 20px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fff500;
  --mdc-radio-selected-hover-icon-color: #fff500;
  --mdc-radio-selected-icon-color: #fff500;
  --mdc-radio-selected-pressed-icon-color: #fff500;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fff500;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fff500;
  --mdc-radio-selected-hover-icon-color: #fff500;
  --mdc-radio-selected-icon-color: #fff500;
  --mdc-radio-selected-pressed-icon-color: #fff500;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fff500;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc3545;
  --mdc-radio-selected-hover-icon-color: #dc3545;
  --mdc-radio-selected-icon-color: #dc3545;
  --mdc-radio-selected-pressed-icon-color: #dc3545;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #dc3545;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "museo-sans", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #fff500;
  --mdc-slider-focus-handle-color: #fff500;
  --mdc-slider-hover-handle-color: #fff500;
  --mdc-slider-active-track-color: #fff500;
  --mdc-slider-inactive-track-color: #fff500;
  --mdc-slider-with-tick-marks-inactive-container-color: #fff500;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-mdc-slider-ripple-color: #fff500;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 245, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 245, 0, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #fff500;
  --mdc-slider-focus-handle-color: #fff500;
  --mdc-slider-hover-handle-color: #fff500;
  --mdc-slider-active-track-color: #fff500;
  --mdc-slider-inactive-track-color: #fff500;
  --mdc-slider-with-tick-marks-inactive-container-color: #fff500;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-mdc-slider-ripple-color: #fff500;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 245, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 245, 0, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #dc3545;
  --mdc-slider-focus-handle-color: #dc3545;
  --mdc-slider-hover-handle-color: #dc3545;
  --mdc-slider-active-track-color: #dc3545;
  --mdc-slider-inactive-track-color: #dc3545;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc3545;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-mdc-slider-ripple-color: #dc3545;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 53, 69, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 53, 69, 0.2);
}

html {
  --mdc-slider-label-label-text-font: museo-sans, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: museo-sans, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fff500;
  --mdc-radio-selected-hover-icon-color: #fff500;
  --mdc-radio-selected-icon-color: #fff500;
  --mdc-radio-selected-pressed-icon-color: #fff500;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fff500;
  --mdc-radio-selected-hover-icon-color: #fff500;
  --mdc-radio-selected-icon-color: #fff500;
  --mdc-radio-selected-pressed-icon-color: #fff500;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc3545;
  --mdc-radio-selected-hover-icon-color: #dc3545;
  --mdc-radio-selected-icon-color: #dc3545;
  --mdc-radio-selected-pressed-icon-color: #dc3545;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fff500;
  --mdc-checkbox-selected-hover-icon-color: #fff500;
  --mdc-checkbox-selected-icon-color: #fff500;
  --mdc-checkbox-selected-pressed-icon-color: #fff500;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fff500;
  --mdc-checkbox-selected-hover-state-layer-color: #fff500;
  --mdc-checkbox-selected-pressed-state-layer-color: #fff500;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fff500;
  --mdc-checkbox-selected-hover-icon-color: #fff500;
  --mdc-checkbox-selected-icon-color: #fff500;
  --mdc-checkbox-selected-pressed-icon-color: #fff500;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fff500;
  --mdc-checkbox-selected-hover-state-layer-color: #fff500;
  --mdc-checkbox-selected-pressed-state-layer-color: #fff500;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc3545;
  --mdc-checkbox-selected-hover-icon-color: #dc3545;
  --mdc-checkbox-selected-icon-color: #dc3545;
  --mdc-checkbox-selected-pressed-icon-color: #dc3545;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc3545;
  --mdc-checkbox-selected-hover-state-layer-color: #dc3545;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc3545;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #fff500;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #fff500;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: museo-sans, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: museo-sans, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: museo-sans, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px "museo-sans", sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: museo-sans, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #fff500;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fff500;
  --mat-tab-header-active-ripple-color: #fff500;
  --mat-tab-header-inactive-ripple-color: #fff500;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fff500;
  --mat-tab-header-active-hover-label-text-color: #fff500;
  --mat-tab-header-active-focus-indicator-color: #fff500;
  --mat-tab-header-active-hover-indicator-color: #fff500;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #fff500;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fff500;
  --mat-tab-header-active-ripple-color: #fff500;
  --mat-tab-header-inactive-ripple-color: #fff500;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fff500;
  --mat-tab-header-active-hover-label-text-color: #fff500;
  --mat-tab-header-active-focus-indicator-color: #fff500;
  --mat-tab-header-active-hover-indicator-color: #fff500;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc3545;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #dc3545;
  --mat-tab-header-active-ripple-color: #dc3545;
  --mat-tab-header-inactive-ripple-color: #dc3545;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc3545;
  --mat-tab-header-active-hover-label-text-color: #dc3545;
  --mat-tab-header-active-focus-indicator-color: #dc3545;
  --mat-tab-header-active-hover-indicator-color: #dc3545;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #fff500;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #fff500;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc3545;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: museo-sans, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fff500;
  --mdc-checkbox-selected-hover-icon-color: #fff500;
  --mdc-checkbox-selected-icon-color: #fff500;
  --mdc-checkbox-selected-pressed-icon-color: #fff500;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fff500;
  --mdc-checkbox-selected-hover-state-layer-color: #fff500;
  --mdc-checkbox-selected-pressed-state-layer-color: #fff500;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fff500;
  --mdc-checkbox-selected-hover-icon-color: #fff500;
  --mdc-checkbox-selected-icon-color: #fff500;
  --mdc-checkbox-selected-pressed-icon-color: #fff500;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fff500;
  --mdc-checkbox-selected-hover-state-layer-color: #fff500;
  --mdc-checkbox-selected-pressed-state-layer-color: #fff500;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc3545;
  --mdc-checkbox-selected-hover-icon-color: #dc3545;
  --mdc-checkbox-selected-icon-color: #dc3545;
  --mdc-checkbox-selected-pressed-icon-color: #dc3545;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc3545;
  --mdc-checkbox-selected-hover-state-layer-color: #dc3545;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc3545;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "museo-sans", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #fff500;
  --mat-text-button-state-layer-color: #fff500;
  --mat-text-button-ripple-color: rgba(255, 245, 0, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #fff500;
  --mat-text-button-state-layer-color: #fff500;
  --mat-text-button-ripple-color: rgba(255, 245, 0, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc3545;
  --mat-text-button-state-layer-color: #dc3545;
  --mat-text-button-ripple-color: rgba(220, 53, 69, 0.1);
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #fff500;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #fff500;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc3545;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #fff500;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #fff500;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc3545;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button:hover, .mat-mdc-raised-button:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-mdc-raised-button:active, .mat-mdc-raised-button:focus:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #fff500;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #fff500;
  --mat-outlined-button-ripple-color: rgba(255, 245, 0, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #fff500;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #fff500;
  --mat-outlined-button-ripple-color: rgba(255, 245, 0, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc3545;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #dc3545;
  --mat-outlined-button-ripple-color: rgba(220, 53, 69, 0.1);
}

.mat-mdc-button {
  --mdc-text-button-container-height: 36px;
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-height: 36px;
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-container-height: 36px;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-container-height: 36px;
}

html {
  --mdc-text-button-label-text-font: museo-sans, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: museo-sans, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: museo-sans, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: museo-sans, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #fff500;
  --mat-icon-button-state-layer-color: #fff500;
  --mat-icon-button-ripple-color: rgba(255, 245, 0, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #fff500;
  --mat-icon-button-state-layer-color: #fff500;
  --mat-icon-button-ripple-color: rgba(255, 245, 0, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc3545;
  --mat-icon-button-state-layer-color: #dc3545;
  --mat-icon-button-ripple-color: rgba(220, 53, 69, 0.1);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary,
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #fff500;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent,
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #fff500;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn,
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc3545;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "museo-sans", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
html {
  --mdc-extended-fab-label-text-font: museo-sans, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #fff500;
}

html {
  --mdc-snackbar-supporting-text-font: museo-sans, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: museo-sans, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: museo-sans, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: museo-sans, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #fff500;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #fff500;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc3545;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #fff500;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #fff500;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #dc3545;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: museo-sans, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: museo-sans, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: museo-sans, sans-serif;
  --mat-standard-button-toggle-text-font: museo-sans, sans-serif;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #fff500;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 245, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 245, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 245, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #fff500;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 245, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #fff500;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 245, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 245, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 245, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 245, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc3545;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 53, 69, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 53, 69, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 53, 69, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 53, 69, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #fff500;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc3545;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: museo-sans, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: museo-sans, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: museo-sans, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #fff500;
}
.mat-icon.mat-accent {
  --mat-icon-color: #fff500;
}
.mat-icon.mat-warn {
  --mat-icon-color: #dc3545;
}

html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #fff500;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #fff500;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #fff500;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #dc3545;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #dc3545;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #fff500;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #fff500;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #fff500;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc3545;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc3545;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc3545;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: museo-sans, sans-serif;
  --mat-stepper-header-label-text-font: museo-sans, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #fff500;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #fff500;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc3545;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: museo-sans, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: museo-sans, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.app-container .mat-mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: var(--bs-light-border-subtle);
  --mdc-checkbox-unselected-hover-icon-color: var(--bs-tertiary-color);
  --mdc-checkbox-state-layer-size: 32px;
}
.app-container .mat-mdc-checkbox .mdc-form-field {
  align-items: start;
  color: var(--bs-body-color);
}
.app-container .mat-mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background: var(--bs-tertiary-bg);
}
.app-container .mat-mdc-checkbox .mdc-checkbox__background {
  border-radius: 5px;
}
.app-container .mat-mdc-checkbox .mdc-checkbox__checkmark {
  padding: 2px;
}
.app-container .mat-mdc-checkbox label {
  margin-top: 0.35em;
}
.app-container .mat-mdc-select-value,
.app-container .mat-mdc-select-arrow {
  color: var(--bs-primary-800);
}
.app-container .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--bs-primary-bg-subtle);
}
.app-container .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--bs-primary-600);
}
.app-container .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--bs-primary-800);
}
.app-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-color: var(--bs-primary);
}
.app-container .mat-mdc-form-field {
  width: 100%;
}

:root,
body {
  --mdc-plain-tooltip-container-color: var(--bs-blue-800);
  --mdc-plain-tooltip-supporting-text-size: 0.87rem;
  --mdc-plain-tooltip-supporting-text-line-height: 1.5rem;
  --mdc-plain-tooltip-supporting-text-tracking: 0;
}

body .mdc-tooltip__surface {
  padding: 1rem;
  max-width: 300px;
  box-shadow: 0 0 140px rgba(255, 255, 255, 0.1);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

li {
  list-style-type: none;
}

button,
a {
  all: unset;
  cursor: pointer;
}

blockquote,
q {
  quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.h6, .app .fstr-cookie-notice .fstr-cookie-notice__title,
h6, .h5,
h5, .h4,
h4, .h3,
h3, .h2,
h2, .h1,
h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "museo", serif;
  font-weight: 700;
  line-height: 1.1;
  color: var(--bs-heading-color);
  word-break: break-word;
  text-wrap: balance;
  hyphens: auto;
}

.h1,
h1 {
  font-size: calc(1.525rem + 3.3vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 4rem;
  }
}

.h2,
h2 {
  font-size: calc(1.4625rem + 2.55vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 3.375rem;
  }
}

.h3,
h3 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 3rem;
  }
}

.h4,
h4 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 2.25rem;
  }
}

.h5,
h5 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h5,
  h5 {
    font-size: 2rem;
  }
}

.h6, .app .fstr-cookie-notice .fstr-cookie-notice__title,
h6 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h6, .app .fstr-cookie-notice .fstr-cookie-notice__title,
  h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  line-height: 1.8;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .lead {
    font-size: 1rem;
  }
}

.display-1 {
  font-size: calc(2.6625rem + 16.95vw);
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 15.375rem;
  }
}

.display-2 {
  font-size: calc(2.2375rem + 11.85vw);
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 11.125rem;
  }
}

.display-3 {
  font-size: calc(1.80625rem + 6.675vw);
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 6.8125rem;
  }
}

.display-4 {
  font-size: calc(1.55rem + 3.6vw);
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 4.25rem;
  }
}

.display-5 {
  font-size: calc(1.4625rem + 2.55vw);
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3.375rem;
  }
}

.display-6 {
  font-size: calc(1.2875rem + 0.45vw);
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.625rem;
  }
}

.fs-display-1 {
  font-size: calc(2.6625rem + 16.95vw);
}
@media (min-width: 1200px) {
  .fs-display-1 {
    font-size: 15.375rem;
  }
}

.fs-display-2 {
  font-size: calc(2.2375rem + 11.85vw);
}
@media (min-width: 1200px) {
  .fs-display-2 {
    font-size: 11.125rem;
  }
}

.fs-display-3 {
  font-size: calc(1.80625rem + 6.675vw);
}
@media (min-width: 1200px) {
  .fs-display-3 {
    font-size: 6.8125rem;
  }
}

.fs-display-4 {
  font-size: calc(1.55rem + 3.6vw);
}
@media (min-width: 1200px) {
  .fs-display-4 {
    font-size: 4.25rem;
  }
}

.fs-display-5 {
  font-size: calc(1.4625rem + 2.55vw);
}
@media (min-width: 1200px) {
  .fs-display-5 {
    font-size: 3.375rem;
  }
}

.fs-display-6 {
  font-size: calc(1.2875rem + 0.45vw);
}
@media (min-width: 1200px) {
  .fs-display-6 {
    font-size: 1.625rem;
  }
}

.link {
  color: var(--bs-primary);
  text-decoration: underline;
}
.link:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

strong {
  font-weight: bold;
}

.text-bg-primary {
  color: white;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1));
  color: black;
}

.text-bg-secondary {
  color: white;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-success {
  color: white;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1));
  color: black;
}

.text-bg-info {
  color: white;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-warning {
  color: white;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-danger {
  color: white;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-light {
  color: white;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1));
  color: black;
}

.text-bg-dark {
  color: white;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-muted {
  color: white;
  background-color: RGBA(var(--bs-muted-rgb), var(--bs-bg-opacity, 1));
}

body {
  --bs-primary: hsl(57.6470588235deg, 100%, 50%);
  --bs-primary-h: 57.6470588235deg;
  --bs-primary-s: 100%;
  --bs-primary-l: 50%;
  --bs-secondary: hsl(199.7604790419deg, 91.2568306011%, 35.8823529412%);
  --bs-secondary-h: 199.7604790419deg;
  --bs-secondary-s: 91.2568306011%;
  --bs-secondary-l: 35.8823529412%;
  --bs-success: hsl(108.2608695652deg, 46.4646464646%, 61.1764705882%);
  --bs-success-h: 108.2608695652deg;
  --bs-success-s: 46.4646464646%;
  --bs-success-l: 61.1764705882%;
  --bs-info: hsl(199.7604790419deg, 91.2568306011%, 35.8823529412%);
  --bs-info-h: 199.7604790419deg;
  --bs-info-s: 91.2568306011%;
  --bs-info-l: 35.8823529412%;
  --bs-warning: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-warning-h: 27.2961373391deg;
  --bs-warning-s: 98.3122362869%;
  --bs-warning-l: 53.5294117647%;
  --bs-danger: hsl(354.251497006deg, 70.4641350211%, 53.5294117647%);
  --bs-danger-h: 354.251497006deg;
  --bs-danger-s: 70.4641350211%;
  --bs-danger-l: 53.5294117647%;
  --bs-light: hsl(0deg, 0%, 100%);
  --bs-light-h: 0deg;
  --bs-light-s: 0%;
  --bs-light-l: 100%;
  --bs-dark: hsl(234.375deg, 94.1176470588%, 6.6666666667%);
  --bs-dark-h: 234.375deg;
  --bs-dark-s: 94.1176470588%;
  --bs-dark-l: 6.6666666667%;
  --bs-muted: hsl(210deg, 10.8108108108%, 70.9803921569%);
  --bs-muted-h: 210deg;
  --bs-muted-s: 10.8108108108%;
  --bs-muted-l: 70.9803921569%;
  --bs-blue: hsl(199.7604790419deg, 91.2568306011%, 35.8823529412%);
  --bs-blue-h: 199.7604790419deg;
  --bs-blue-s: 91.2568306011%;
  --bs-blue-l: 35.8823529412%;
  --bs-blue-800: hsl(224.6808510638deg, 79.6610169492%, 11.568627451%);
  --bs-blue-800-h: 224.6808510638deg;
  --bs-blue-800-s: 79.6610169492%;
  --bs-blue-800-l: 11.568627451%;
  --bs-blue-900: hsl(231.2195121951deg, 95.3488372093%, 8.431372549%);
  --bs-blue-900-h: 231.2195121951deg;
  --bs-blue-900-s: 95.3488372093%;
  --bs-blue-900-l: 8.431372549%;
  --bs-indigo: hsl(262.8318584071deg, 89.6825396825%, 50.5882352941%);
  --bs-indigo-h: 262.8318584071deg;
  --bs-indigo-s: 89.6825396825%;
  --bs-indigo-l: 50.5882352941%;
  --bs-purple: hsl(261.2598425197deg, 50.5976095618%, 50.7843137255%);
  --bs-purple-h: 261.2598425197deg;
  --bs-purple-s: 50.5976095618%;
  --bs-purple-l: 50.7843137255%;
  --bs-pink: hsl(326.8695652174deg, 100%, 45.0980392157%);
  --bs-pink-h: 326.8695652174deg;
  --bs-pink-s: 100%;
  --bs-pink-l: 45.0980392157%;
  --bs-red: hsl(354.251497006deg, 70.4641350211%, 53.5294117647%);
  --bs-red-h: 354.251497006deg;
  --bs-red-s: 70.4641350211%;
  --bs-red-l: 53.5294117647%;
  --bs-orange: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-orange-h: 27.2961373391deg;
  --bs-orange-s: 98.3122362869%;
  --bs-orange-l: 53.5294117647%;
  --bs-yellow: hsl(57.6470588235deg, 100%, 50%);
  --bs-yellow-h: 57.6470588235deg;
  --bs-yellow-s: 100%;
  --bs-yellow-l: 50%;
  --bs-green: hsl(108.2608695652deg, 46.4646464646%, 61.1764705882%);
  --bs-green-h: 108.2608695652deg;
  --bs-green-s: 46.4646464646%;
  --bs-green-l: 61.1764705882%;
  --bs-teal: hsl(162.2485207101deg, 72.5321888412%, 45.6862745098%);
  --bs-teal-h: 162.2485207101deg;
  --bs-teal-s: 72.5321888412%;
  --bs-teal-l: 45.6862745098%;
  --bs-cyan: hsl(188.198757764deg, 77.7777777778%, 40.5882352941%);
  --bs-cyan-h: 188.198757764deg;
  --bs-cyan-s: 77.7777777778%;
  --bs-cyan-l: 40.5882352941%;
  --bs-gray-900: hsl(210deg, 10.8108108108%, 14.5098039216%);
  --bs-gray-900-h: 210deg;
  --bs-gray-900-s: 10.8108108108%;
  --bs-gray-900-l: 14.5098039216%;
  --bs-gray-800: hsl(210deg, 10.3448275862%, 22.7450980392%);
  --bs-gray-800-h: 210deg;
  --bs-gray-800-s: 10.3448275862%;
  --bs-gray-800-l: 22.7450980392%;
  --bs-gray-700: hsl(210deg, 8.75%, 31.3725490196%);
  --bs-gray-700-h: 210deg;
  --bs-gray-700-s: 8.75%;
  --bs-gray-700-l: 31.3725490196%;
  --bs-gray-600: hsl(208.2352941176deg, 7.2961373391%, 45.6862745098%);
  --bs-gray-600-h: 208.2352941176deg;
  --bs-gray-600-s: 7.2961373391%;
  --bs-gray-600-l: 45.6862745098%;
  --bs-gray-500: hsl(210deg, 10.8108108108%, 70.9803921569%);
  --bs-gray-500-h: 210deg;
  --bs-gray-500-s: 10.8108108108%;
  --bs-gray-500-l: 70.9803921569%;
  --bs-gray-400: hsl(210deg, 13.9534883721%, 83.137254902%);
  --bs-gray-400-h: 210deg;
  --bs-gray-400-s: 13.9534883721%;
  --bs-gray-400-l: 83.137254902%;
  --bs-gray-300: hsl(210deg, 13.7931034483%, 88.6274509804%);
  --bs-gray-300-h: 210deg;
  --bs-gray-300-s: 13.7931034483%;
  --bs-gray-300-l: 88.6274509804%;
  --bs-gray-200: hsl(210deg, 15.7894736842%, 92.5490196078%);
  --bs-gray-200-h: 210deg;
  --bs-gray-200-s: 15.7894736842%;
  --bs-gray-200-l: 92.5490196078%;
  --bs-gray-100: hsl(210deg, 16.6666666667%, 97.6470588235%);
  --bs-gray-100-h: 210deg;
  --bs-gray-100-s: 16.6666666667%;
  --bs-gray-100-l: 97.6470588235%;
  --bs-font-sans-serif: "museo-sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-gutter-x: 1rem;
  --bs-primary-100: #fffdcc;
  --bs-primary-200: #fffb99;
  --bs-primary-300: #fff966;
  --bs-primary-400: #fff733;
  --bs-primary-500: #fff500;
  --bs-primary-600: #ccc400;
  --bs-primary-700: #999300;
  --bs-primary-800: #666200;
  --bs-primary-900: #333100;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --topbar-bar-height: 140px;
}
@media (max-width: 767.98px) {
  :root {
    --topbar-bar-height: 75px;
  }
}
@media (max-width: 575.98px) {
  :root {
    --topbar-bar-height: 90px;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body::before {
  pointer-events: none;
  background-color: var(--bs-body-bg);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  z-index: 0;
}
body .app-bg-noise {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.8;
  mix-blend-mode: soft-light;
}

img {
  width: 100%;
  height: auto;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
}

.bg-success {
  background-color: var(--bs-success) !important;
}

.bg-info {
  background-color: var(--bs-info) !important;
}

.bg-warning {
  background-color: var(--bs-warning) !important;
}

.bg-danger {
  background-color: var(--bs-danger) !important;
}

.bg-light {
  background-color: var(--bs-light) !important;
}

.bg-dark {
  background-color: var(--bs-dark) !important;
}

.bg-muted {
  background-color: var(--bs-muted) !important;
}

.bg-color-blue {
  background-color: var(--bs-blue) !important;
}

.bg-color-blue-800 {
  background-color: var(--bs-blue-800) !important;
}

.bg-color-blue-900 {
  background-color: var(--bs-blue-900) !important;
}

.bg-color-indigo {
  background-color: var(--bs-indigo) !important;
}

.bg-color-purple {
  background-color: var(--bs-purple) !important;
}

.bg-color-pink {
  background-color: var(--bs-pink) !important;
}

.bg-color-red {
  background-color: var(--bs-red) !important;
}

.bg-color-orange {
  background-color: var(--bs-orange) !important;
}

.bg-color-yellow {
  background-color: var(--bs-yellow) !important;
}

.bg-color-green {
  background-color: var(--bs-green) !important;
}

.bg-color-teal {
  background-color: var(--bs-teal) !important;
}

.bg-color-cyan {
  background-color: var(--bs-cyan) !important;
}

.bg-gray-900 {
  background-color: var(--bs-gray-900) !important;
}

.bg-gray-800 {
  background-color: var(--bs-gray-800) !important;
}

.bg-gray-700 {
  background-color: var(--bs-gray-700) !important;
}

.bg-gray-600 {
  background-color: var(--bs-gray-600) !important;
}

.bg-gray-500 {
  background-color: var(--bs-gray-500) !important;
}

.bg-gray-400 {
  background-color: var(--bs-gray-400) !important;
}

.bg-gray-300 {
  background-color: var(--bs-gray-300) !important;
}

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important;
}

.bg-gray-100 {
  background-color: var(--bs-gray-100) !important;
}

/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */
[class^=section-] {
  position: relative;
}

[class^=section-negative-] {
  z-index: 1;
}

.section-inset-y {
  padding-top: 10vw;
  padding-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-y {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.section-inset-x {
  padding-left: 10vw;
  padding-right: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-x {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}

.section-inset-s {
  padding-left: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-s {
    padding-left: 6.25rem;
  }
}

.section-inset-t {
  padding-top: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-t {
    padding-top: 6.25rem;
  }
}

.section-inset-b {
  padding-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-b {
    padding-bottom: 6.25rem;
  }
}

.section-outset-y {
  margin-top: 10vw;
  margin-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-y {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}

.section-outset-x {
  margin-left: 10vw;
  margin-right: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-x {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }
}

.section-outset-s {
  margin-left: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-s {
    margin-left: 6.25rem;
  }
}

.section-outset-t {
  margin-top: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-t {
    margin-top: 6.25rem;
  }
}

.section-outset-b {
  margin-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-b {
    margin-bottom: 6.25rem;
  }
}

.section-negative-y {
  margin-top: -10vw;
  margin-bottom: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-y {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
}

.section-negative-x {
  margin-left: -10vw;
  margin-right: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-x {
    margin-left: -6.25rem;
    margin-right: -6.25rem;
  }
}

.section-negative-s {
  margin-left: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-s {
    margin-left: -6.25rem;
  }
}

.section-negative-t {
  margin-top: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-t {
    margin-top: -6.25rem;
  }
}

.section-negative-b {
  margin-bottom: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-b {
    margin-bottom: -6.25rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none:hover, .text-decoration-none:focus {
  text-decoration: none;
}

.font-display {
  font-family: "museo", serif;
}

.link-unset,
.block-link {
  text-decoration: none;
  color: currentColor;
}
.link-unset:focus-visible,
.block-link:focus-visible {
  outline: 2px solid var(--bs-warning);
  outline-offset: 3px;
}

.fw-semi {
  font-weight: 300 !important;
}

.fw-bolder {
  font-weight: 900 !important;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.text-secondary {
  color: var(--bs-secondary) !important;
}

.text-success {
  color: var(--bs-success) !important;
}

.text-info {
  color: var(--bs-info) !important;
}

.text-warning {
  color: var(--bs-warning) !important;
}

.text-danger {
  color: var(--bs-danger) !important;
}

.text-light {
  color: var(--bs-light) !important;
}

.text-dark {
  color: var(--bs-dark) !important;
}

.text-muted {
  color: var(--bs-muted) !important;
}

.font-handwritten {
  font-family: "felt-tip-woman", sans-serif !important;
}

@media screen and (min-width: 800px) {
  .container-size-xs {
    max-width: 635px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-sm {
    max-width: 960px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-md {
    max-width: 1200px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-xl {
    max-width: 1400px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-xxl {
    max-width: 1600px;
  }
}

.container {
  --container-max-width: 100%;
}
@media (max-width: 767.98px) {
  .container {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media (min-width: 576px) {
  .container, .container-xsm, .container-sm {
    --container-max-width: 800px;
  }
}
@media (min-width: 768px) {
  .container, .container-xsm, .container-sm, .container-md {
    --container-max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg {
    --container-max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl {
    --container-max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    --container-max-width: 1320px;
  }
}
.text-max-width {
  max-inline-size: 720px;
  text-wrap: balance;
}
.text-max-width-xxs {
  max-inline-size: 480px;
}
.text-max-width-xs {
  max-inline-size: 550px;
}
.text-max-width-sm {
  max-inline-size: 720px;
}
.text-max-width-md {
  max-inline-size: 900px;
}
.text-max-width-lg {
  max-inline-size: 1000px;
}
.text-max-width-xl {
  max-inline-size: 1140px;
}
.text-max-width-xxl {
  max-inline-size: 1320px;
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xxl {
    max-inline-size: 1320px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xxl {
    max-inline-size: 1320px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xxl {
    max-inline-size: 1320px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xxl {
    max-inline-size: 1320px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xxl {
    max-inline-size: 1320px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xxl {
    max-inline-size: 1320px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xxs {
    max-inline-size: 480px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xs {
    max-inline-size: 550px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-sm {
    max-inline-size: 720px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-md {
    max-inline-size: 900px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-lg {
    max-inline-size: 1000px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xl {
    max-inline-size: 1140px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xxl {
    max-inline-size: 1320px !important;
  }
}

[data-object-position=topleft],
.object-position--topleft {
  object-position: top left;
}

[data-mask-position=topleft],
.mask-position--topleft {
  mask-position: top left;
}

[data-object-position=topcenter],
.object-position--topcenter {
  object-position: top center;
}

[data-mask-position=topcenter],
.mask-position--topcenter {
  mask-position: top center;
}

[data-object-position=topright],
.object-position--topright {
  object-position: top right;
}

[data-mask-position=topright],
.mask-position--topright {
  mask-position: top right;
}

[data-object-position=middleleft],
.object-position--middleleft {
  object-position: center left;
}

[data-mask-position=middleleft],
.mask-position--middleleft {
  mask-position: center left;
}

[data-object-position=middlecenter],
.object-position--middlecenter {
  object-position: center center;
}

[data-mask-position=middlecenter],
.mask-position--middlecenter {
  mask-position: center center;
}

[data-object-position=middleright],
.object-position--middleright {
  object-position: center right;
}

[data-mask-position=middleright],
.mask-position--middleright {
  mask-position: center right;
}

[data-object-position=bottomleft],
.object-position--bottomleft {
  object-position: bottom left;
}

[data-mask-position=bottomleft],
.mask-position--bottomleft {
  mask-position: bottom left;
}

[data-object-position=bottomcenter],
.object-position--bottomcenter {
  object-position: bottom center;
}

[data-mask-position=bottomcenter],
.mask-position--bottomcenter {
  mask-position: bottom center;
}

[data-object-position=bottomright],
.object-position--bottomright {
  object-position: bottom right;
}

[data-mask-position=bottomright],
.mask-position--bottomright {
  mask-position: bottom right;
}

.inset-s-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-1 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-1 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-2 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-2 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-3 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-3 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-4 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-4 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-5 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-5 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-6 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-6 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-7 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-7 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-8 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-8 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-9 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-9 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-10 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-10 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-11 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-11 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-12 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-12 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-xs-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
}

.inset-e-xs-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
}

.inset-s-xs-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
}

.inset-e-xs-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
}

.inset-s-xs-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
}

.inset-e-xs-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
}

.inset-s-xs-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
}

.inset-e-xs-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
}

.inset-s-xs-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
}

.inset-e-xs-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
}

.inset-s-xs-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
}

.inset-e-xs-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
}

.inset-s-xs-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
}

.inset-e-xs-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
}

.inset-s-xs-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
}

.inset-e-xs-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
}

.inset-s-xs-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
}

.inset-e-xs-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
}

.inset-s-xs-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
}

.inset-e-xs-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
}

.inset-s-xs-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
}

.inset-e-xs-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
}

.inset-s-xs-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
}

.inset-e-xs-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
}

@media (min-width: 390px) {
  .inset-s-xsm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
  .inset-e-xsm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
  .inset-e-xsm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
  .inset-e-xsm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
  .inset-e-xsm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
  .inset-e-xsm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
  .inset-e-xsm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
  .inset-e-xsm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
  .inset-e-xsm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
  .inset-e-xsm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
  .inset-e-xsm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
  .inset-e-xsm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
  .inset-e-xsm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
  .inset-e-sm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
  .inset-e-sm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
  .inset-e-sm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
  .inset-e-sm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
  .inset-e-sm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
  .inset-e-sm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
  .inset-e-sm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
  .inset-e-sm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
  .inset-e-sm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
  .inset-e-sm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
  .inset-e-sm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
  .inset-e-sm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
  .inset-e-md-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
  .inset-e-md-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
  .inset-e-md-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
  .inset-e-md-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
  .inset-e-md-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
  .inset-e-md-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
  .inset-e-md-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
  .inset-e-md-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
  .inset-e-md-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
  .inset-e-md-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
  .inset-e-md-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
  .inset-e-md-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
  .inset-e-lg-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
  .inset-e-lg-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
  .inset-e-lg-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
  .inset-e-lg-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
  .inset-e-lg-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
  .inset-e-lg-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
  .inset-e-lg-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
  .inset-e-lg-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
  .inset-e-lg-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
  .inset-e-lg-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
  .inset-e-lg-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
  .inset-e-lg-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
  .inset-e-xl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
  .inset-e-xl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
  .inset-e-xl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
  .inset-e-xl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
  .inset-e-xl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
  .inset-e-xl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
  .inset-e-xl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
  .inset-e-xl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
  .inset-e-xl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
  .inset-e-xl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
  .inset-e-xl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
  .inset-e-xl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
  .inset-e-xxl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 1 + var(--bs-gutter-x) * 1) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
  .inset-e-xxl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 2 + var(--bs-gutter-x) * 2) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
  .inset-e-xxl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 3 + var(--bs-gutter-x) * 3) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
  .inset-e-xxl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 4 + var(--bs-gutter-x) * 4) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
  .inset-e-xxl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 5 + var(--bs-gutter-x) * 5) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
  .inset-e-xxl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 6 + var(--bs-gutter-x) * 6) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
  .inset-e-xxl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 7 + var(--bs-gutter-x) * 7) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
  .inset-e-xxl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 8 + var(--bs-gutter-x) * 8) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
  .inset-e-xxl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 9 + var(--bs-gutter-x) * 9) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
  .inset-e-xxl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 10 + var(--bs-gutter-x) * 10) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
  .inset-e-xxl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 11 + var(--bs-gutter-x) * 11) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
  .inset-e-xxl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc((var(--one-col) * 12 + var(--bs-gutter-x) * 12) * -1);
  }
}
.pull-s,
.pull-e {
  --space-left: -0.5rem;
}
@media (min-width: 992px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.5rem)) / 2) * -1);
  }
}
@media (min-width: 1200px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.5rem)) / 2) * -1);
  }
}
@media (min-width: 1400px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.5rem)) / 2) * -1);
  }
}

.pull-s {
  margin-left: var(--space-left);
}

.pull-e {
  margin-right: var(--space-left);
}

@media (max-width: 767.98px) {
  .row {
    --bs-gutter-x: 0.5rem;
  }
}

.flex-gap-1 {
  gap: 0.25rem;
}
.flex-gap-2 {
  gap: 0.5rem;
}
.flex-gap-3 {
  gap: 1rem;
}

.custom-scrollbar::-webkit-scrollbar-track, .markdown-content table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar, .markdown-content table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .markdown-content table::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}

.button, button,
input[type=button] {
  overflow: visible;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  border: 0;
  outline: none;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none; /* for input */
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; /* for button */
}
.button:focus, button:focus,
input[type=button]:focus, .button:hover, button:hover,
input[type=button]:hover {
  outline: none;
  text-decoration: none;
}

.button {
  --button-background: var(--bs-muted);
  --button-background-darker: hsl(var(--bs-muted-h), var(--bs-muted-s), calc(var(--bs-muted-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-muted-h), var(--bs-muted-s), var(--bs-muted-l), 0.2);
  --button-color: white;
  --button-size: 2.625rem;
  --button-padding-x: 1.5rem;
  --button-border-width: 1px;
  --button-border-radius: 2.625rem;
  display: inline-flex;
  position: relative;
  height: var(--button-size);
  align-items: center;
  padding-right: var(--button-padding-x);
  padding-left: var(--button-padding-x);
  padding-bottom: 0.1em;
  font-family: "museo", serif;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  background-color: var(--button-background);
  color: var(--button-color);
  fill: currentColor;
  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid transparent;
  cursor: pointer;
  justify-content: center;
}
@media (min-width: 768px) {
  .button {
    --button-size: 3.15rem;
    --button-padding-x: 1.8rem;
  }
}
@media (hover: hover) {
  .button:hover {
    background-color: var(--button-background-darker);
    color: var(--button-color);
  }
}
.button:disabled, .button[disabled=true] {
  pointer-events: none;
  opacity: 0.4;
}

.button {
  --icon-inset-factor: -0.5;
}
.button span + fstr-icon,
.button span + .css-icon {
  margin-inline-start: 0.5rem;
}
.button .icon-chip + span,
.button fstr-icon + span,
.button .css-icon + span {
  margin-inline-start: 0.5rem;
}
.button fstr-icon .icon {
  fill: currentColor;
}
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) fstr-icon:not(span + fstr-icon),
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) .css-icon:not(span + .css-icon) {
  margin-inline-start: calc(24px * var(--icon-inset-factor));
}
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) span + fstr-icon,
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) span + .css-icon {
  margin-inline-end: calc(24px * var(--icon-inset-factor));
}
.button .icon-chip {
  --chip-spacing: 6px;
  --icon-inset-factor: -1;
  width: calc(var(--button-size) - var(--chip-spacing) * 2);
  height: calc(var(--button-size) - var(--chip-spacing) * 2);
  background: white;
  border-radius: 50%;
  fill: var(--button-background);
  color: var(--button-background);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing));
}
.button .icon-chip svg {
  width: 18px;
  height: 18px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.button span + .icon-chip {
  margin-inline-end: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing));
}
.button.is--inactive {
  opacity: 0.5;
}

.button-icon {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 12.5rem;
  display: inline-flex;
  justify-content: center;
  padding: 0;
}
.button-icon fstr-icon {
  margin-inline-start: 0;
}
.button-icon span + fstr-icon {
  margin-inline-end: 0;
}

.button.button-link {
  background: transparent;
  color: var(--button-background);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.button.button-link fstr-icon {
  min-width: 1.5em;
  min-height: 1.5em;
}
.button.button-link fstr-icon .icon {
  fill: currentColor;
}
@media (hover: hover) {
  .button.button-link:hover {
    background: transparent;
    color: var(--button-background-darker);
  }
}

.button.button-outline {
  background: transparent;
  color: var(--button-background);
  border-color: var(--button-background);
}
.button.button-outline fstr-icon .icon {
  fill: currentColor;
}
@media (hover: hover) {
  .button.button-outline:hover {
    background: var(--button-background-alpha);
    color: var(--button-background-darker);
    border-color: var(--button-background-darker);
  }
}

.button--lg {
  --button-size: 3.875rem;
  --button-padding-x: 1.9375rem;
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .button--lg {
    font-size: 1.5rem;
  }
}
.button--lg.button-icon {
  width: 3.875rem;
  height: 3.875rem;
  min-width: 3.875rem;
}

.button--md {
  --button-size: 3.125rem;
  --button-padding-x: 1.5625rem;
  font-size: 1.25rem;
}
.button--md.button-icon {
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
}

.button--sm, .app .fstr-cookie-notice .button {
  --button-size: 2.625rem;
  --button-padding-x: 1.3125rem;
  font-size: 1rem;
}
.button--sm.button-icon, .app .fstr-cookie-notice .button-icon.button {
  width: 2.625rem;
  height: 2.625rem;
  min-width: 2.625rem;
}

.button--xs {
  --button-size: 2rem;
  --button-padding-x: 1rem;
  font-size: 0.9375rem;
}
.button--xs.button-icon {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}

.button--xxs {
  --button-size: 1.5rem;
  --button-padding-x: 0.75rem;
}
.button--xxs.button-icon {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
}

.button.button--primary {
  --button-background: var(--bs-primary);
  --button-background-darker: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-primary-h), var(--bs-primary-s), var(--bs-primary-l), 0.2);
  --button-color: var(--bs-dark);
}

.button.button--secondary {
  --button-background: var(--bs-secondary);
  --button-background-darker: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-secondary-h), var(--bs-secondary-s), var(--bs-secondary-l), 0.2);
  --button-color: var(--bs-secondary-subtle);
}

.button.button--dark {
  --button-background: var(--bs-dark);
  --button-background-darker: hsl(var(--bs-dark-h), var(--bs-dark-s), calc(var(--bs-dark-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-dark-h), var(--bs-dark-s), var(--bs-dark-l), 0.2);
  --button-color: white;
}

.button.button--warning {
  --button-background: var(--bs-warning);
  --button-background-darker: hsl(var(--bs-warning-h), var(--bs-warning-s), calc(var(--bs-warning-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-warning-h), var(--bs-warning-s), var(--bs-warning-l), 0.2);
  --button-color: white;
}

.button.button--danger {
  --button-background: var(--bs-danger);
  --button-background-darker: hsl(var(--bs-danger-h), var(--bs-danger-s), calc(var(--bs-danger-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-danger-h), var(--bs-danger-s), var(--bs-danger-l), 0.2);
  --button-color: white;
}

.button.button--light {
  --button-background: var(--bs-light);
  --button-background-darker: hsl(var(--bs-light-h), var(--bs-light-s), calc(var(--bs-light-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-light-h), var(--bs-light-s), var(--bs-light-l), 0.2);
  --button-color: var(--bs-dark);
}

fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1.5em;
  min-height: 1.5em;
}
fstr-icon .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: currentColor;
}
fstr-icon .icon[class*=is--line-icon] * {
  fill: none;
  stroke: currentColor;
}

.icon-lg {
  width: 32px;
  height: 32px;
}
.icon-lg svg {
  width: 2rem;
  height: 2rem;
}

.icon-md {
  width: 28px;
  height: 28px;
}
.icon-md svg {
  width: 1.75rem;
  height: 1.75rem;
}

.icon-sm {
  width: 22px;
  height: 22px;
}
.icon-sm svg {
  width: 1.375rem;
  height: 1.375rem;
}

.icon-xs {
  width: 16px;
  height: 16px;
}
.icon-xs svg {
  width: 1rem;
  height: 1rem;
}

.anim--spinning {
  animation: spin 2.2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
.icon.is--icon-play {
  top: -0.02em;
}

.markdown-content {
  line-height: 1.6;
}
.markdown-content blockquote {
  position: relative;
  padding: 0 0 1.25em;
  margin: 4ex 0 4ex 0;
  font-family: "museo", serif;
  text-align: center;
  display: flex;
  flex-flow: column;
}
.markdown-content blockquote::before {
  content: "“";
  font-size: 3em;
  font-family: "museo", serif;
  font-weight: bold;
  color: var(--bs-primary);
  height: 1.5ex;
  line-height: 1;
  display: inline-block;
}
.markdown-content blockquote * {
  font-size: 1.2em;
}
.markdown-content blockquote *:last-child {
  margin-bottom: 0;
}
.markdown-content .fr-img-space-wrap {
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}
.markdown-content .fr-inner {
  color: var(--bs-secondary-color);
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}
.markdown-content img {
  max-width: 100%;
}
.markdown-content table {
  margin-top: 1.25rem;
  margin-bottom: 1em;
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: block;
}
@media (max-width: 767.98px) {
  .markdown-content table {
    width: calc(100% + 1rem);
    margin-right: 1rem;
  }
}
.markdown-content table th {
  background: var(--bs-primary);
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 0.7rem 0.5rem;
}
.markdown-content table tr td:not(:last-child) {
  border-right: 1px solid var(--bs-blue-900);
}
.markdown-content table tr th:not(:last-child) {
  border-right: 1px solid var(--bs-blue-900);
}
.markdown-content table tr td {
  padding: 0.5rem;
  min-width: 180px;
}
.markdown-content table tr td.fr-thick {
  font-weight: bold;
}
.markdown-content table tr td.fr-highlighted {
  border: 2px solid var(--bs-secondary);
}
.markdown-content table tr:nth-child(even) td {
  background: var(--bs-blue-800);
}
.markdown-content em,
.markdown-content i {
  font-style: italic;
}
.markdown-content strong {
  font-weight: 800;
}
.markdown-content * + h1,
.markdown-content * + .h1 {
  margin-top: 4rem;
}
.markdown-content h1,
.markdown-content .h1 {
  font-size: 2.625rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content * + h2,
.markdown-content * + .h2 {
  margin-top: 4rem;
}
.markdown-content h2,
.markdown-content .h2 {
  font-size: 2.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content * + h3,
.markdown-content * + .h3 {
  margin-top: 4rem;
}
.markdown-content h3,
.markdown-content .h3 {
  font-size: 1.75rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content * + h4,
.markdown-content * + .h4 {
  margin-top: 4rem;
}
.markdown-content h4,
.markdown-content .h4 {
  font-size: 1.375rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content * + h5,
.markdown-content * + .h5 {
  margin-top: 4rem;
}
.markdown-content h5,
.markdown-content .h5 {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content * + h6,
.markdown-content * + .h6,
.markdown-content .app .fstr-cookie-notice * + .fstr-cookie-notice__title,
.app .fstr-cookie-notice .markdown-content * + .fstr-cookie-notice__title {
  margin-top: 4rem;
}
.markdown-content h6,
.markdown-content .h6,
.markdown-content .app .fstr-cookie-notice .fstr-cookie-notice__title,
.app .fstr-cookie-notice .markdown-content .fstr-cookie-notice__title {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content ul,
.markdown-content ol {
  list-style: outside;
  padding-inline-start: 2rem;
  display: inline-block;
  margin-bottom: 1.25rem;
  width: 100%;
}
.markdown-content ul li,
.markdown-content ol li {
  display: list-item;
  list-style-type: initial;
  font-weight: 400;
}
.markdown-content ol li {
  list-style-type: decimal;
}
.markdown-content hr {
  margin: 1.5em 0;
}
.markdown-content p {
  margin-bottom: 3ex;
}
.markdown-content pre {
  white-space: pre-line;
  font-family: monospace;
  background: var(--bs-gray-100);
  padding: 1em;
  border-radius: 7px;
  margin-bottom: 1.5em;
}
.markdown-content .button + .button {
  margin-inline-start: 0.5em;
}
.markdown-content a:not(.button) {
  color: var(--bs-primary);
  text-decoration: underline;
  font-weight: 600;
}
@media (hover: hover) {
  .markdown-content a:not(.button):hover {
    color: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.8));
  }
}
.markdown-content .fr-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 2ex;
}
.markdown-content .fr-video iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.markdown-content iframe,
.markdown-content object,
.markdown-content embed {
  width: 100%;
  height: auto;
}

.simple-markdown {
  line-height: 1.6;
}
.simple-markdown em,
.simple-markdown i {
  font-style: italic;
}
.simple-markdown strong {
  font-weight: 800;
}
.simple-markdown * + h1,
.simple-markdown * + .h1 {
  margin-top: 4rem;
}
.simple-markdown h1,
.simple-markdown .h1 {
  font-size: 2.625rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.simple-markdown * + h2,
.simple-markdown * + .h2 {
  margin-top: 4rem;
}
.simple-markdown h2,
.simple-markdown .h2 {
  font-size: 2.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.simple-markdown * + h3,
.simple-markdown * + .h3 {
  margin-top: 4rem;
}
.simple-markdown h3,
.simple-markdown .h3 {
  font-size: 1.75rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.simple-markdown * + h4,
.simple-markdown * + .h4 {
  margin-top: 4rem;
}
.simple-markdown h4,
.simple-markdown .h4 {
  font-size: 1.375rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.simple-markdown * + h5,
.simple-markdown * + .h5 {
  margin-top: 4rem;
}
.simple-markdown h5,
.simple-markdown .h5 {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.simple-markdown * + h6,
.simple-markdown * + .h6,
.simple-markdown .app .fstr-cookie-notice * + .fstr-cookie-notice__title,
.app .fstr-cookie-notice .simple-markdown * + .fstr-cookie-notice__title {
  margin-top: 4rem;
}
.simple-markdown h6,
.simple-markdown .h6,
.simple-markdown .app .fstr-cookie-notice .fstr-cookie-notice__title,
.app .fstr-cookie-notice .simple-markdown .fstr-cookie-notice__title {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.simple-markdown ul,
.simple-markdown ol {
  list-style: outside;
  padding-inline-start: 2rem;
  display: inline-block;
  margin-bottom: 1.25rem;
  width: 100%;
}
.simple-markdown ul li,
.simple-markdown ol li {
  display: list-item;
  list-style-type: initial;
  font-weight: 400;
}
.simple-markdown ol li {
  list-style-type: decimal;
}
.simple-markdown hr {
  margin: 1.5em 0;
}
.simple-markdown p {
  margin-bottom: 3ex;
}
.simple-markdown pre {
  white-space: pre-line;
  font-family: monospace;
  background: var(--bs-gray-100);
  padding: 1em;
  border-radius: 7px;
  margin-bottom: 1.5em;
}
.simple-markdown .button + .button {
  margin-inline-start: 0.5em;
}
.simple-markdown a:not(.button) {
  color: var(--bs-primary);
  text-decoration: underline;
  font-weight: 600;
}
@media (hover: hover) {
  .simple-markdown a:not(.button):hover {
    color: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.8));
  }
}
.simple-markdown p:last-child {
  margin-bottom: 0;
}
.simple-markdown strong {
  color: var(--bs-secondary);
}
.simple-markdown .fr-video,
.simple-markdown img,
.simple-markdown .fr-img-space-wrap,
.simple-markdown .fr-inner,
.simple-markdown .embed-removed {
  display: none;
}

.embed-removed {
  background-color: var(--bs-background);
  text-align: center;
  padding: 2em 1em;
  margin: 2em 0;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embed-removed .fstr-cookie-btn.button {
  background: var(--bs-secondary);
}
.embed-removed .fstr-cookie-btn.button:hover {
  background: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
}
.embed-removed .embed-removed__text {
  font-weight: 600;
  color: var(--bs-background-text-color);
  margin-bottom: 1em;
}

.app .fstr-cookie-notice {
  --cookie-notice-bg: var(--bs-secondary);
  --cookie-success-color: var(--bs-success);
  --cookie-muted-color: #0878af;
  --cookie-disabled-color: #061235;
  border-radius: 20px !important;
  max-width: 600px;
  border-width: 0;
  border-radius: 20px;
  padding: 2rem;
  color: var(--bs-light);
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: auto;
  top: 50%;
  width: 90%;
  background-color: #010420;
  box-shadow: 0 20px 32px -22px rgba(0, 0, 0, 0.25), 0 -20px 32px -22px rgba(0, 0, 0, 0.25);
}
.app .fstr-cookie-notice a {
  color: var(--bs-primary);
}
.app .fstr-cookie-notice .button-group {
  margin-top: 10px;
}
.app .fstr-cookie-notice .button-group .button--reject {
  display: none;
}
.app .fstr-cookie-notice .fstr-cookie-notice__title {
  margin-bottom: 1ex;
  color: var(--bs-primary);
  font-weight: 900;
}
.app .fstr-cookie-notice .fstr-cookie-notice__toggle-row__content,
.app .fstr-cookie-notice .fstr-cookie-notice__content {
  font-size: 0.9375rem;
}
.app .fstr-cookie-notice .fstr-cookie-notice__toggle-row__content.open {
  padding: 1ex 0;
}
.app .fstr-cookie-notice .toggle-button {
  margin-left: 1ex;
  width: auto;
  height: auto;
  background-color: transparent;
  fill: #fff500;
  color: #fff500;
  padding: 0;
}
.app .fstr-cookie-notice .toggle-button span:first-of-type {
  display: none;
}
.app .fstr-cookie-notice .toggle-button .chevron {
  margin-left: 0;
}
.app .fstr-cookie-notice .button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 767.98px) {
  .app .fstr-cookie-notice .button-group .button {
    width: 100%;
  }
}
.app .fstr-cookie-notice .button {
  margin-bottom: 0 !important;
  text-align: center;
  justify-content: center;
}
.app .fstr-cookie-notice .button--settings {
  border: 2px solid;
  color: var(--bs-primary);
  background-color: transparent;
}
.app .fstr-cookie-notice .button--close {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 40px;
  height: 40px;
  font-size: 0px;
  background-color: transparent;
  padding: 0;
  color: var(--bs-primary);
}
.app .fstr-cookie-notice .button--close::after, .app .fstr-cookie-notice .button--close::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background-color: currentColor;
}
.app .fstr-cookie-notice .button--close::after {
  transform: translate3d(-50%, -50%) rotate3d(0, 0, 1, -45deg, 0);
}
.app .fstr-cookie-notice .button--close::before {
  transform: translate3d(-50%, -50%) rotate3d(0, 0, 1, 45deg, 0);
}
@media (max-width: 767.98px) {
  .app .fstr-cookie-notice .button--close {
    max-width: 40px;
  }
}
.app .fstr-cookie-notice .toggle input[type=checkbox]:disabled ~ label .toggle__switch::after {
  background-color: var(--bs-secondary);
  opacity: 0.5;
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--bs-secondary-100);
  margin-bottom: 3ex;
}
.embed-removed__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
  text-align: center;
}
@media (max-width: 575.98px) {
  .embed-removed__inner {
    height: auto;
  }
}
.embed-removed .fstr-cookie-btn.button {
  font-size: 0.9em;
  margin-top: 3ex;
  background-color: var(--bs-primary);
}

.badge {
  font-size: 15px;
  font-weight: 900;
  padding: 0 1.5ex;
  line-height: 1.4375rem;
  border-radius: 2px;
  font-family: "museo", serif;
  display: inline-flex;
  align-items: center;
  background-color: var(--badge-default-bg);
  color: var(--badge-default-color);
}

.badge--primary {
  --badge-default-bg: var(--bs-primary);
  --badge-default-color: var(--bs-primary-bg-subtle);
}

.badge--secondary {
  --badge-default-bg: var(--bs-secondary);
  --badge-default-color: var(--bs-secondary-bg-subtle);
}

.badge--warning {
  --badge-default-bg: var(--bs-warning);
  --badge-default-color: white;
}

.badge--danger {
  --badge-default-bg: var(--bs-danger);
  --badge-default-color: white;
}

.badge--success {
  --badge-default-bg: var(--bs-success);
  --badge-default-color: var(--bs-dark);
}

.badge--info {
  --badge-default-bg: var(--bs-info);
  --badge-default-color: white;
}

.debug {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  background: black;
  color: white;
  width: clamp(100px, 300px, 100vw);
  border: 1px solid white;
  font-family: monospace;
  padding: 5px;
  max-height: 300px;
  overflow: auto;
}

.pulse {
  width: 50px;
  height: 50px;
  position: relative;
  pointer-events: none;
  z-index: 4;
  animation: scaleIn 0.3s ease-out both;
}
.pulse__ring {
  position: absolute;
  background-color: var(--bs-light);
  height: 100%;
  width: 100%;
  border-radius: 100%;
  opacity: 0.4;
  left: 0;
  top: 0;
  animation: pulsing 2.4s ease-out infinite;
}
.pulse__ring:nth-of-type(1) {
  animation-delay: -0.5s;
}
.pulse__ring:nth-of-type(2) {
  animation-delay: -1s;
}
.pulse__ring:nth-of-type(3) {
  animation-delay: -1.5s;
}
@media (min-width: 576px) {
  .pulse {
    width: 80px;
    height: 80px;
  }
}

@keyframes pulsing {
  100% {
    transform: scale(1.75);
    opacity: 0;
  }
}
@keyframes scaleIn {
  0% {
    transform: translate3d(-50%, -50%) scale(0.5, 0);
  }
  100% {
    transform: translate3d(-50%, -50%) scale(1, 0);
  }
}
.background-circles {
  aspect-ratio: 1/1;
}
.background-circles circle {
  stroke-width: var(--stroke-width, 1);
  stroke: var(--stroke-color, #fff);
  stroke-dasharray: var(--stroke-dasharray, 13px);
}

.intro-circle-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transform: translate3d(-55%, -45%, 0);
  z-index: 2;
  opacity: 0.5;
  width: 50vw;
  height: 50vw;
}
.intro-circle-bg.bg--blue {
  background: radial-gradient(circle, hsla(var(--bs-blue-h), var(--bs-blue-s), var(--bs-blue-l), 0.67) 20%, hsla(var(--bs-blue-h), var(--bs-blue-s), var(--bs-blue-l), 0) 60%);
}
.intro-circle-bg.bg--magenta {
  transform: translate3d(-20%, -75%, 0);
  background: radial-gradient(circle, hsla(var(--bs-pink-h), var(--bs-pink-s), var(--bs-pink-l), 0.67) 20%, hsla(var(--bs-pink-h), var(--bs-pink-s), var(--bs-pink-l), 0) 60%);
}

.delay--1 {
  --delay: 200ms;
}

.delay--2 {
  --delay: 500ms;
}

.delay--3 {
  --delay: 600ms;
}

.delay--4 {
  --delay: 800ms;
}

.delay--5 {
  --delay: 1200ms;
}

.anim-fade-up {
  opacity: 0;
  transform: translate3d(0, 20%, 0);
  transition: transform 0.5s var(--delay, 0s), opacity 0.5s var(--delay, 0s);
}
.anim-fade-up.anim--active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.reveal-effect .words {
  background: var(--text-underlying-color, #ccc) linear-gradient(to right, var(--text-color, #000) 0%, var(--text-color, #000) 100%) 0/0 no-repeat;
  background-clip: text;
  color: transparent;
  background-size: calc(var(--scroll-text-currrent-char) * 1ch);
}
.reveal-effect .is--highlighted .word::after {
  content: attr(data-word);
  position: absolute;
  background: var(--text-underlying-color, var(--bs-primary)) linear-gradient(to right, var(--bs-primary) 0%, var(--bs-primary) 100%) 0/0 no-repeat;
  z-index: 2;
  transform: translate3d(-100%, 0px, 0);
  background-clip: text;
  color: transparent;
  background-size: calc((var(--scroll-text-currrent-char) - var(--char-index)) * 1ch);
}

.video {
  position: relative;
}
@media (max-width: 767.98px) {
  .video {
    margin-bottom: 1em;
  }
}
.video__overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
}
.video__overlay img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.video__overlay.is--hidden {
  opacity: 0;
  pointer-events: none;
}

.fstr-youtube__container {
  padding: 0 !important;
  height: auto !important;
  aspect-ratio: 16/9;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-expansion-panel.is--disabled .mat-expansion-panel-header:not([aria-disabled=true]) {
  cursor: default;
}

.mat-expansion-panel {
  --padding-offset: 20px;
  margin-left: calc(var(--padding-offset) * -1);
  border-radius: 10px !important;
  overflow: hidden;
  background: transparent;
  transition: background 0.3s ease-in-out;
}
.mat-expansion-panel .mat-expansion-panel-header {
  padding: 15px var(--padding-offset);
}
.mat-expansion-panel__content {
  padding: 0 var(--padding-offset) var(--padding-offset) var(--padding-offset);
}
.mat-expansion-panel.is--disabled {
  cursor: default;
}
@media (min-width: 768px) {
  .mat-expansion-panel.has-no--text .mat-expansion-indicator {
    display: none;
  }
  .mat-expansion-panel.has-no--text .mat-expansion-panel__content {
    display: none;
  }
}
.mat-expansion-panel .mat-expansion-panel-content {
  pointer-events: auto;
}
.mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}
.mat-expansion-panel.is--only-panel-with-content {
  pointer-events: none;
}
.mat-expansion-panel:not(.is--disabled).mat-expanded {
  background: linear-gradient(57deg, #010420 19.82%, rgba(40, 7, 56, 0) 84.66%);
}
.mat-expansion-panel:not(.is--disabled):not(.mat-expanded):hover {
  background: linear-gradient(57deg, rgba(1, 4, 32, 0.5) 19.82%, rgba(40, 7, 56, 0) 84.66%);
}
.mat-expansion-panel:not(.is--disabled):not(.mat-expanded):hover .mat-expansion-panel-header {
  color: var(--bs-primary);
}

mat-accordion .mat-expansion-panel {
  background: none;
  color: white;
}
mat-accordion .mat-expansion-panel-header-title {
  color: white;
  align-items: flex-start !important;
}
mat-accordion .mat-expansion-panel-header {
  height: auto !important;
}
mat-accordion .mat-expansion-indicator::after {
  transition: transform 0.2s;
}
mat-accordion .mat-expanded .mat-expansion-indicator::after {
  translate: 0 -50%;
}
mat-accordion .mat-expansion-indicator::after {
  color: white;
}
mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: transparent;
}

.hamburger {
  height: calc(var(--hamburger-spacing) * 2 + var(--hamburger-line-height) * 3);
  width: var(--hamburger-width);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--bs-primary);
  padding-top: 2px;
}
.hamburger__lines {
  position: relative;
  background: transparent;
}
.hamburger__lines::before, .hamburger__lines::after {
  width: var(--hamburger-width);
  height: var(--hamburger-line-height);
  display: inline-block;
  content: "";
  background: currentColor;
  transform-origin: calc(50% - 1px) 50%;
}
.hamburger__lines::before {
  position: absolute;
  top: auto;
  right: 0;
  bottom: var(--hamburger-spacing);
  left: 0;
  transition: background 0.2s, bottom 0.2s 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger__lines::after {
  position: absolute;
  top: var(--hamburger-spacing);
  right: 0;
  bottom: auto;
  left: 0;
  transition: background 0.2s, top 0.2s 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger.is--active .hamburger__lines {
  background: transparent !important;
}
.hamburger.is--active .hamburger__lines::before {
  bottom: 0;
  transition: background 0.2s, bottom 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate3d(0, 0, 1, 45deg);
}
.hamburger.is--active .hamburger__lines::after {
  top: 0;
  transition: background 0.2s, top 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate3d(0, 0, 1, -45deg);
}

.hamburger {
  transition: transform 0.2s ease-in-out;
}
.hamburger-button {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.hamburger-button__icon {
  transition: transform 0.2s linear;
  transform: rotate3d(0, 0, 1, 0deg);
}
.hamburger-button:hover .hamburger-button__icon {
  transform: scale(0.8);
}
.hamburger.is--active .hamburger-button__icon {
  transform: rotate3d(0, 0, 1, -135deg);
}

.app {
  position: relative;
}

.app__router {
  position: relative;
  z-index: 5;
}

.main-nav--active {
  position: absolute;
  overflow: hidden;
  max-height: 100vh;
  width: 100vw;
}

.block-default {
  --block-spacing: 40px;
  padding: var(--block-spacing);
  position: relative;
  display: flex;
  align-items: flex-end;
}
@media (min-width: 1200px) {
  .block-default {
    --block-spacing: 60px;
  }
}
.block-default::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}
.block-default__figure {
  position: absolute;
  top: var(--block-spacing);
  right: var(--block-spacing);
  bottom: var(--block-spacing);
  left: var(--block-spacing);
  width: calc(100% - var(--block-spacing) * 2);
  height: calc(100% - var(--block-spacing) * 2);
  z-index: 1;
}
.block-default__content {
  position: relative;
  z-index: 2;
}
.block-default__content__label {
  position: absolute;
  left: calc(var(--block-spacing) * -0.5);
  transform: rotate3d(0, 0, 1, -90deg);
  transform-origin: left center;
  animation: width 2s infinite;
  bottom: -0.9em;
}
@media (min-width: 1200px) {
  .block-default__content__label {
    font-size: 1.5em;
  }
}

:root {
  --badge-default-bg: var(--bs-primary);
  --badge-default-color: var(--bs-primary-bg-subtle);
}

:root,
[data-bs-theme=light] {
  --bs-blue: #0878af;
  --bs-blue-800: #061235;
  --bs-blue-900: #01072a;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e6007f;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fff500;
  --bs-green: #80ca6e;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-gray-900: #212529;
  --bs-gray-800: #343a40;
  --bs-gray-700: #495057;
  --bs-gray-600: #6c757d;
  --bs-gray-500: #adb5bd;
  --bs-gray-400: #ced4da;
  --bs-gray-300: #dee2e6;
  --bs-gray-200: #e9ecef;
  --bs-gray-100: #f8f9fa;
  --bs-primary: #fff500;
  --bs-secondary: #0878af;
  --bs-success: #80ca6e;
  --bs-info: #0878af;
  --bs-warning: #fd7e14;
  --bs-danger: #dc3545;
  --bs-light: white;
  --bs-dark: #010421;
  --bs-muted: #adb5bd;
  --bs-primary-rgb: 255, 245, 0;
  --bs-secondary-rgb: 8, 120, 175;
  --bs-success-rgb: 128, 202, 110;
  --bs-info-rgb: 8, 120, 175;
  --bs-warning-rgb: 253, 126, 20;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 1, 4, 33;
  --bs-muted-rgb: 173, 181, 189;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #01072a;
  --bs-secondary-bg-subtle: #01072a;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "museo-sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "museo-sans", sans-serif;
  --bs-body-font-size: 18;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.5;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #01072a;
  --bs-body-bg-rgb: 1, 7, 42;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #fff;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 10px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 20px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(1, 4, 33, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(1, 4, 33, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(1, 4, 33, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(1, 4, 33, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.content-page {
  max-width: 100%;
  overflow: hidden;
}

.section {
  position: relative;
}
.section__background-blur {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 1200px;
  transform: translate(-10%, -15%);
  pointer-events: none;
}

.footer {
  text-align: center;
}

.footer__nav-list {
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.grid-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
.grid-overlay .grid-showcase__inner {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(255, 0, 0, 0.1490196078);
  outline: 1px solid rgba(255, 0, 0, 0.2901960784);
}

.breakpoint-util-list {
  position: fixed;
  top: 0;
  right: 0;
}
.breakpoint-util-list__item {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;
  background: var(--bs-primary);
  color: white;
  padding: 10px 30px;
}

@keyframes loader {
  0% {
    transform: translateX(0%) scaleX(0);
  }
  50% {
    transform: translateX(33%) scaleX(33%);
  }
  100% {
    transform: translateX(100%) scaleX(0%);
  }
}
.loading {
  position: fixed;
  top: 0;
  height: 5px;
  background: #dddddd;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
}
.loading__progression {
  background: var(--bs-primary);
}
.loading__progression.type--flux {
  width: 100vw;
  animation: loader 2s infinite ease;
  transform-origin: left;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert--primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert--secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert--success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert--info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert--warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert--danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert--light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert--dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert--muted {
  --bs-alert-color: var(--bs-muted-text-emphasis);
  --bs-alert-bg: var(--bs-muted-bg-subtle);
  --bs-alert-border-color: var(--bs-muted-border-subtle);
  --bs-alert-link-color: var(--bs-muted-text-emphasis);
}

.landingpage__header {
  margin-bottom: -5ex;
}
@media (min-width: 768px) {
  .landingpage__header {
    margin-bottom: -10ex;
  }
}

:root {
  --badge-default-bg: var(--bs-primary);
  --badge-default-color: var(--bs-primary-bg-subtle);
}

:root,
[data-bs-theme=light] {
  --bs-blue: #0878af;
  --bs-blue-800: #061235;
  --bs-blue-900: #01072a;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e6007f;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fff500;
  --bs-green: #80ca6e;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-gray-900: #212529;
  --bs-gray-800: #343a40;
  --bs-gray-700: #495057;
  --bs-gray-600: #6c757d;
  --bs-gray-500: #adb5bd;
  --bs-gray-400: #ced4da;
  --bs-gray-300: #dee2e6;
  --bs-gray-200: #e9ecef;
  --bs-gray-100: #f8f9fa;
  --bs-primary: #fff500;
  --bs-secondary: #0878af;
  --bs-success: #80ca6e;
  --bs-info: #0878af;
  --bs-warning: #fd7e14;
  --bs-danger: #dc3545;
  --bs-light: white;
  --bs-dark: #010421;
  --bs-muted: #adb5bd;
  --bs-primary-rgb: 255, 245, 0;
  --bs-secondary-rgb: 8, 120, 175;
  --bs-success-rgb: 128, 202, 110;
  --bs-info-rgb: 8, 120, 175;
  --bs-warning-rgb: 253, 126, 20;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 1, 4, 33;
  --bs-muted-rgb: 173, 181, 189;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #01072a;
  --bs-secondary-bg-subtle: #01072a;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "museo-sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "museo-sans", sans-serif;
  --bs-body-font-size: 18;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.5;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #01072a;
  --bs-body-bg-rgb: 1, 7, 42;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #fff;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 10px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 20px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(1, 4, 33, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(1, 4, 33, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(1, 4, 33, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(1, 4, 33, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.logo-intro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100vh;
}
.logo-intro__wrapper {
  animation: fade-in-intro 0.4s 0.2s ease-in-out both;
}
.logo-intro__title {
  position: relative;
  z-index: 10;
  font-weight: 700;
}
.logo-intro__title svg {
  width: 60vw;
  height: 50vh;
  max-width: 670px;
  max-height: 230px;
}
.logo-intro .char {
  will-change: transform;
  fill: #fff;
  position: relative;
  animation: blur-and-zoom 3s infinite;
  transform-origin: center center;
}
.logo-intro .char:nth-child(2), .logo-intro .char:nth-child(1), .logo-intro .char:nth-child(5) {
  filter: url(#svgBlur);
}
.logo-intro .char:nth-child(3), .logo-intro .char:nth-child(4) {
  filter: url(#svgBlur-2);
}
.logo-intro .char:nth-child(1) {
  animation-delay: 0s;
}
.logo-intro .char:nth-child(2) {
  animation-delay: 1s;
}
.logo-intro .char:nth-child(3) {
  animation-delay: 1.3s;
}
.logo-intro .char:nth-child(4) {
  animation-delay: 0.6s;
}
.logo-intro .char:nth-child(5) {
  animation-delay: 1.4s;
}
.logo-intro .intro-circle-bg {
  opacity: 0.8;
  will-change: opacity, transform;
}
.logo-intro .intro-circle-bg.bg--blue {
  animation: bg-animation-blue 12s infinite both;
}
.logo-intro .intro-circle-bg.bg--magenta {
  animation: bg-animation-magenta 10s 0.3s infinite both;
}

@keyframes blur-and-zoom {
  0%, 100% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
@keyframes fade-in-intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bg-animation-blue {
  0%, 100% {
    opacity: 0.5;
    transform: translate3d(-65%, -80%, 0);
  }
  50% {
    opacity: 0.8;
    transform: translate3d(-70%, -40%, 0);
  }
}
@keyframes bg-animation-magenta {
  0%, 100% {
    opacity: 0.5;
    transform: translate3d(-40%, -25%, 0);
  }
  50% {
    opacity: 0.8;
    transform: translate3d(-20%, -75%, 0);
  }
}
.theme-groen,
[data-bs-theme=groen] {
  --bs-secondary: #80ca6e;
  --bs-secondary: hsl(108.2608695652deg, 46.4646464646%, 61.1764705882%);
  --bs-secondary-h: 108.2608695652deg;
  --bs-secondary-s: 46.4646464646%;
  --bs-secondary-l: 61.1764705882%;
}

.theme-geel,
[data-bs-theme=geel] {
  --bs-secondary: #fff500;
  --bs-secondary-bg-subtle: #01072a;
  --bs-secondary-subtle: #01072a;
  --bs-secondary: hsl(57.6470588235deg, 100%, 50%);
  --bs-secondary-h: 57.6470588235deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 50%;
}

.theme-magenta,
[data-bs-theme=magenta] {
  --bs-secondary: #e6007f;
  --bs-secondary: hsl(326.8695652174deg, 100%, 45.0980392157%);
  --bs-secondary-h: 326.8695652174deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 45.0980392157%;
}