.app {
  position: relative;
}

.app__router {
  position: relative;
  //min-height: calc(var(--vh, 1vh) * 100);
  z-index: 5;
}

.main-nav--active {
  position: absolute;
  overflow: hidden;
  max-height: 100vh;
  width: 100vw;
}
