:root,
body {
  --mdc-plain-tooltip-container-color: var(--bs-blue-800);
  --mdc-plain-tooltip-supporting-text-size: 0.87rem;
  --mdc-plain-tooltip-supporting-text-line-height: 1.5rem;
  --mdc-plain-tooltip-supporting-text-tracking: 0;
}

body .mdc-tooltip__surface {
  padding: 1rem;
  max-width: 300px;
  box-shadow: 0 0 140px rgba(255, 255, 255, 0.1);
}
