// 1: color
// 2: background-color

$badges-styles: (
  primary: (
    var(--bs-primary-bg-subtle),
    var(--bs-primary),
  ),
  secondary: (
    var(--bs-secondary-bg-subtle),
    var(--bs-secondary),
  ),
  warning: (
    white,
    var(--bs-warning),
  ),
  danger: (
    white,
    var(--bs-danger),
  ),
  success: (
    var(--bs-dark),
    var(--bs-success),
  ),
  info: (
    white,
    var(--bs-info),
  ),
);

:root {
  --badge-default-bg: var(--bs-primary);
  --badge-default-color: var(--bs-primary-bg-subtle);
}
